<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        searchable
        ref="vendorTrackShipmentList"
        title="Track Shipment Logs"
        :table-header="columns"
        :table-data="tableData"
        :context="context"
        :total="totalItems"
        name="vendorTrackShipmentLogs"
        localStorageKey="vendorTrackShipmentColumns"
        @getList="getVendorTrackShipmentLogsList"
      >
        <template #leftFilterSlot>
          <v-btn
            x-small
            color="primary"
            fab
            depressed
            class="ma-1 rounded-lg"
            @click="showFilterDialog = true"
            ><v-icon> mdi-filter</v-icon>
          </v-btn>
        </template>
        <template #dialogs>
          <ExportReportDialog
            title="Vendor Track Shipment"
            v-model="showExportDialog"
            request_type="Vendor Shipment Track"
            request_for="shipment_track"
          >
          </ExportReportDialog>
          <RequestedLogsFilterDialog
            title="vendor_track_shipment_logs_"
            v-model="showFilterDialog"
            @applyFilters="applyFilters"
          >
          </RequestedLogsFilterDialog>
        </template>
        <template #rightSlot>
          <v-btn
            color="primary"
            small
            class="ma-1"
            @click="showExportDialog = true"
          >
            <v-icon size="16">mdi-export</v-icon>Export Data
          </v-btn>
        </template>
      </BaseListLayout>
    </v-col>
    <v-col
      cols="4"
      v-if="hasTrackShipmentLogsDetails"
      class="pt-0 left-devider-shadow overflow-hidden-y"
    >
      <BaseLogsDetailsLayout
        :trace_id="trackShipmentLogsObject.trace_id"
        v-model="hasTrackShipmentLogsDetails"
        :details="trackShipmentLogsObject.tracking_logs"
        :payloadDetails="trackShipmentLogsObject.payload"
        @closeDetails="hasTrackShipmentLogsDetails = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ActionsButton from "@/components/AgGridButtons/ActionsButton.vue";
import BaseLogsDetailsLayout from "@/components/BaseLayoutComponents/BaseLogsDetailsLayout.vue";
import RequestedLogsFilterDialog from "@/components/common/RequestedLogsFilterDialog.vue";
import ExportReportDialog from "@/components/common/ExportReportDialog.vue";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import { bus } from "@/main";

export default {
  name: "TrackShipmentLogs",
  components: {
    BaseListLayout,
    ActionsButton,
    ExportReportDialog,
    BaseLogsDetailsLayout,
    RequestedLogsFilterDialog,
    CellRenderer,
  },
  data() {
    return {
      totalItems: 0,
      canEdit: false,
      showExportDialog: false,
      showFilterDialog: false,
      hasTrackShipmentLogsDetails: false,
      trackShipmentLogsObject: {},
      tableData: [],
      columns: [
        { headerName: "Vendor AWB", field: "vendor_awb" },
        { headerName: "Organization", field: "organization_name" },
        { headerName: "Vendor AWB Status", field: "vendor_awb_status" },
        { headerName: "AWB Number", field: "master_awb" },
        { headerName: "Tracking Number", field: "track_no" },
        {
          headerName: "Organization Last Status",
          field: "last_status_sent_to_org",
        },
        {
          headerName: "Last Update Sent At",
          field: "last_update_sent_to_org_at",
        },
        {
          headerName: "Last Update Received At",
          field: "last_update_received_at",
        },
        {
          headerName: "Created",
          field: "created",
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionsButton",
          minWidth: 100,
        },
      ],
      vendorAwb: "VAWB01",
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    details() {
      return [
        { label: "Method", value: this.trackShipmentLogsObject.method },
        {
          label: "Endpoint",
          value: this.trackShipmentLogsObject.organization_endpoint,
        },
        {
          label: "Vendor Name",
          value: this.trackShipmentLogsObject.vendor,
        },
        {
          label: "Status Code",
          value: this.trackShipmentLogsObject.status_code,
        },
        {
          label: "Organization",
          value: this.trackShipmentLogsObject.organization_name,
        },
        {
          label: "Status",
          value:
            this.trackShipmentLogsObject.is_success == "Yes"
              ? "Succeed"
              : "Failed",
        },
      ];
    },
  },
  methods: {
    getVendorTrackShipmentLogsList(params) {
      bus.$emit("showLoader", true);

      let apiParams = {
        ...params,
        vendor_awb: this.vendorAwb,
      };

      let filters = localStorage.getItem("vendor_track_shipment_logs_Filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("organization" in filters && filters != null) {
        filters.organization = filters.organization.join(",");
      }
      if ("vendor" in filters && filters != null) {
        filters.vendor = filters.vendor.join(",");
      }

      this.$api.vendorAPIRequestLogs
        .getTrackShipmentLogsList({
          ...filters,
          ...apiParams,
        })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          if (res.data && res.data.results) {
            this.tableData = res.data.results;
          }
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    viewDetails(id) {
      this.$api.vendorAPIRequestLogs
        .getTrackShipmentLogsObject(id)
        .then((res) => {
          this.trackShipmentLogsObject = res.data;
          this.hasTrackShipmentLogsDetails = true;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err?.data?.detail,
            color: "red",
          });
        });
    },
    applyFilters() {
      this.$refs.vendorTrackShipmentList.refreshList();
    },
  },
};
</script>

<style>
</style>