<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        searchable
        ref="outboundTrackAWBList"
        title="Track AWB Logs"
        :table-header="columns"
        :table-data="tableData"
        :context="context"
        :total="totalItems"
        name="outboundTrackAWBLogs"
        localStorageKey="outBoundTrackAwbLogsColumns"
        @selectionChanged="selectionChanged"
        @getList="getTrackAWBLogsList"
      >
        <template #leftFilterSlot>
          <v-btn
            color="primary"
            x-small
            fab
            depressed
            class="ma-1 rounded-lg"
            @click="showFilterDialog = true"
            ><v-icon> mdi-filter</v-icon>
          </v-btn>
        </template>
        <template #dialogs>
          <ExportReportDialog
            title="Track AWB"
            v-model="showExportDialog"
            request_type="Outbound"
            request_for="awb_track"
          >
          </ExportReportDialog>
          <RequestedLogsFilterDialog
            title="outbound_track_awb_logs_"
            v-model="showFilterDialog"
            @applyFilters="applyFilters"
          >
          </RequestedLogsFilterDialog>
          <JsonEditorModal
            v-model="showJsonEditorDialog"
            :payload="payload"
            :id="trackAWBLogsId"
            @save="onSave"
          >
          </JsonEditorModal>
        </template>
        <template #rightSlot>
          <v-btn
            color="primary"
            small
            class="ma-1"
            @click="showExportDialog = true"
          >
            <v-icon size="16">mdi-export</v-icon>Export Data
          </v-btn>
          <v-btn
            color="primary"
            v-if="selectedItems.length != 0"
            small
            class="ma-1"
            @click="bulkResend()"
          >
            <v-icon size="16">mdi-upload</v-icon>Bulk Resend
          </v-btn>
        </template>
      </BaseListLayout>
    </v-col>
    <v-col
      cols="4"
      v-if="hasTrackAWBLogsDetails"
      class="pt-0 left-devider-shadow overflow-hidden-y"
    >
      <BaseLogsDetailsLayout
        :trace_id="trackAWBLogsObject.trace_id"
        v-model="hasTrackAWBLogsDetails"
        :details="details"
        :payloadDetails="trackAWBLogsObject.payload"
        @closeDetails="hasTrackAWBLogsDetails = false"
      />
    </v-col>
  </v-row>
</template>
  
  <script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ActionsButton from "@/components/AgGridButtons/ActionsButton.vue";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import JsonEditorModal from "@/components/common/JsonEditorModal.vue";
import BaseLogsDetailsLayout from "@/components/BaseLayoutComponents/BaseLogsDetailsLayout.vue";
import RequestedLogsFilterDialog from "@/components/common/RequestedLogsFilterDialog.vue";
import ExportReportDialog from "@/components/common/ExportReportDialog.vue";
import { bus } from "@/main";

export default {
  name: "TrackAWBLogs",
  components: {
    BaseListLayout,
    ActionsButton,
    CellRenderer,
    BaseLogsDetailsLayout,
    JsonEditorModal,
    ExportReportDialog,
    RequestedLogsFilterDialog,
  },
  data() {
    return {
      totalItems: 0,
      canEdit: false,
      showExportDialog: false,
      showFilterDialog: false,
      showJsonEditorDialog: false,
      hasTrackAWBLogsDetails: false,
      selectedItems: [],
      payload: {},
      tableData: [],
      trackAWBLogsObject: {},
      trackAWBLogsId: null,
      columns: [
        { headerName: "Trace ID", field: "trace_id" },
        {
          headerName: "Created",
          field: "created",
          cellRenderer: "CellRenderer",
        },
        { headerName: "Organization", field: "organization_name" },
        { headerName: "Method", field: "method" },
        { headerName: "Response Code", field: "status_code" },
        {
          headerName: "Success",
          field: "is_success",
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionsButton",
          minWidth: 100,
        },
      ],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    details() {
      return [
        { label: "Method", value: this.trackAWBLogsObject.method },
        {
          label: "Endpoint",
          value: this.trackAWBLogsObject.organization_endpoint,
        },
        {
          label: "Status Code",
          value: this.trackAWBLogsObject.status_code,
        },
        {
          label: "Organization",
          value: this.trackAWBLogsObject.organization_name,
        },
        {
          label: "Status",
          value:
            this.trackAWBLogsObject.is_success == "Yes" ? "Succeed" : "Failed",
        },
      ];
    },
  },
  methods: {
    getTrackAWBLogsList(params) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem("outbound_track_awb_logs_Filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("organization" in filters && filters != null) {
        filters.organization = filters.organization.join(",");
      }
      if ("client" in filters && filters != null) {
        filters.client = filters.client.join(",");
      }

      this.$api.outboundLogs
        .getTrackAWBLogsList({ ...filters, ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.tableData = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    viewDetails(id) {
      this.$api.outboundLogs
        .getTrackAWBLogsObject(id)
        .then((res) => {
          this.trackAWBLogsObject = res.data;
          this.hasTrackAWBLogsDetails = true;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err?.data?.detail,
            color: "red",
          });
        });
    },
    editDetails(id) {
      this.trackAWBLogsId = id;
      this.$api.outboundLogs
        .getTrackAWBLogsObject(id)
        .then((res) => {
          this.payload = res.data.payload;
          this.showJsonEditorDialog = true;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err?.data?.detail,
            color: "red",
          });
        });
    },
    onSave(payload, id) {
      console.log("save", payload, id);
      this.showJsonEditorDialog = false;
    },
    selectionChanged(selectedRows) {
      this.selectedItems = selectedRows;
    },
    bulkResend() {
      console.log("bulk", this.selectedItems);
    },
    applyFilters() {
      this.$refs.outboundTrackAWBList.refreshList();
    },
  },
};
</script>
  