<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        ref="clientList"
        name="ClientList"
        title="Client"
        searchable
        :table-header="columns"
        :table-data="clientList"
        :hasAddButton="true"
        :context="context"
        :total="totalItems"
        localStorageKey="clientColumns"
        @getList="getClientList"
      >
        <template #addButton>
          <v-btn color="primary" small depressed @click="addClient">
            Add Client
          </v-btn>
        </template>
        <template #leftFilterSlot>
          <v-row class="pa-0 ma-0 d-flex">
            <v-col
              v-if="!isUserTypeOrganization"
              cols="3"
              class="pa-0 ma-0 d-flex"
            >
              <BaseSelect
                class="pl-2 rounded-pill"
                label="Organization"
                :isClearable="true"
                :itemsList="organizationList"
                item-text="name"
                item-value="id"
                v-model="filters.organization"
                @change="applyFilter"
              />
            </v-col>
            <v-col cols="3" class="pa-0 ma-0 d-flex">
              <BaseSelect
                class="pl-2 rounded-pill"
                label="Integration Partner"
                :isClearable="true"
                :itemsList="integrationPartnerList"
                item-text="name"
                item-value="id"
                v-model="filters.integration_partner"
                @change="applyFilter"
              />
            </v-col>
          </v-row>
        </template>
        <template #dialogs>
          <ClientForm
            from="Client"
            ref="clientForm"
            v-model="showClientForm"
            :editMode="editMode"
            :clientID="clientID"
            @updateList="updateList"
          />
          <WebhookConfigurationForm
            v-model="showClientConfigurationForm"
            :clientID="clientID"
            @updateList="updateList"
          />
        </template>
      </BaseListLayout>
      <ClientDetails
        v-model="showClientDetails"
        :clientID="clientID"
        @closeDetails="showClientDetails = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ClientForm from "./ClientForm.vue";
import WebhookConfigurationForm from "./WebhookConfigurationForm.vue";
import ActionButton from "@/components/AgGridButtons/ActionButton.vue";
import ClientDetails from "./ClientDetails.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import { bus } from "@/main";
import { getUserProfile } from "@/utils/functions.js";

export default {
  name: "Client",
  components: {
    BaseListLayout,
    ClientForm,
    ActionButton,
    ClientDetails,
    BaseSelect,
    WebhookConfigurationForm,
    CellRenderer,
  },
  data() {
    return {
      fromClient: true,
      canClientConfigure: true,
      clientID: null,
      totalItems: 0,
      clientList: [],
      filters: {},
      editMode: false,
      showClientForm: false,
      showClientDetails: false,
      showClientConfigurationForm: false,
      organizationList: [],
      integrationPartnerList: [],
      commonColumns: [
        { headerName: "Client Code", field: "code" },
        { headerName: "Client Name", field: "name" },
        {
          headerName: "Integration Partner",
          field: "integration_partner_name",
        },
        {
          headerName: "Is Webhook Enabled",
          field: "is_webhook_enabled",
          cellRenderer: "CellRenderer",
        },
        { headerName: "Webhook URL", field: "webhook_url" },
        {
          headerName: "API Key",
          field: "api_key",
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionButton",
          minWidth: 100,
          maxWidth: 100,
        },
      ],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    isUserTypeOrganization() {
      return getUserProfile().user_type == "organization";
    },
    columns() {
      if (this.isUserTypeOrganization) {
        return this.commonColumns;
      } else {
        this.commonColumns.splice(2, 0, {
          headerName: "Organization",
          field: "organization_name",
        });
        return [...this.commonColumns];
      }
    },
  },
  methods: {
    getClientList(params) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem("Client_Filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("search" in filters) {
        filters.search = filters.search.toString();
      }

      this.$api.client
        .getClientList({ ...params, ...filters })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.clientList = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    refreshList() {
      this.$refs.clientList.refreshList();
    },
    updateList() {
      this.$refs.clientList.updateList();
    },
    addClient() {
      this.editMode = false;
      this.showClientForm = true;
    },
    editDetails(id) {
      this.editMode = true;
      this.clientID = id;
      this.showClientForm = true;
    },
    viewDetails(id) {
      this.clientID = id;
      this.showClientDetails = true;
    },
    copyApiKey(api_key) {
      if (api_key) {
        navigator.clipboard.writeText(api_key);
        bus.$emit("showToastMessage", {
          message: "API Key Copied",
          color: "success",
        });
      }
    },
    getOrganizationOptions() {
      this.$api.organization
        .getOrganizationOptions()
        .then((res) => {
          this.organizationList = res.data;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    getIntegrationPartnerSelectionList() {
      this.$api.integrationPartner
        .getIntegrationPartnerSelectionList({ limit: "all" })
        .then((res) => {
          this.integrationPartnerList = res.data;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    applyFilter() {
      localStorage.setItem("Client_Filters", JSON.stringify(this.filters));
      this.refreshList();
    },
    openClientConfigurationForm(id) {
      this.clientID = id;
      this.showClientConfigurationForm = true;
    },
  },
  mounted() {
    if (!this.isUserTypeOrganization) {
      this.getOrganizationOptions();
    }
    this.getIntegrationPartnerSelectionList();
    let f = localStorage.getItem("Client_Filters");
    if (f) {
      this.filters = JSON.parse(f);
    }
  },
};
</script>
