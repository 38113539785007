<template>
  <BaseDialog
    v-model="showClientDetails"
    title="Client Details"
    fullscreen
    @closeDialog="showClientDetails = false"
  >
    <template #dialogContent>
      <v-card elevation="0">
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="11" class="pb-5">
              <span
                class="pl-2 mr-8"
                v-for="detail in basicDetails"
                :key="detail.label"
              >
                <span class="primary--text">{{ detail.label }} -</span>
                <span class="font-weight-bold secondary--text">
                  {{ detail.value }}
                </span>
              </span>
            </v-col>
            <v-col cols="1" class="d-flex justify-end">
              <v-btn text @click="showClientForm = true">
                <v-icon color="primary">mdi-pencil</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-card style="background-color: #f5f8fa" elevation="0">
                <v-card-title class="ma-0 pb-0">
                  <span class="pl-2 font-weight-bold text--primary">
                    Webhook Configuration
                  </span>
                  <v-spacer></v-spacer>
                  <div>
                    <v-btn text @click="showClientConfigurationForm = true">
                      <v-icon color="primary">mdi-pencil</v-icon>
                    </v-btn>
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="4"
                      v-for="list in webhookData"
                      :key="list.label"
                    >
                      <v-card elevation="0">
                        <v-card-title class="ma-0 pb-0">
                          {{ list.label }}
                        </v-card-title>
                        <v-card-text>
                          <v-row
                            v-for="(item, i) in list.data"
                            :key="i"
                            no-gutters
                            class="pb-1 d-flex align-center"
                          >
                            <v-col
                              cols="5"
                              class="d-flex px-1 py-1 justify-between align-center"
                            >
                              {{
                                list.label == "Status"
                                  ? item.organization_status
                                  : item.key
                              }}
                            </v-col>
                            <v-col cols="1">
                              <v-icon color="secondary">
                                mdi-arrow-right-bold
                              </v-icon>
                            </v-col>
                            <v-col cols="6" class="primary--text">
                              <v-text-field
                                v-if="list.label == 'Status'"
                                class="pl-2"
                                outlined
                                hide-details="auto"
                                dense
                                readonly
                                v-model="item.client_status"
                              />
                              <v-text-field
                                v-else
                                class="pl-2"
                                outlined
                                hide-details="auto"
                                dense
                                readonly
                                v-model="item.label_name"
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <ClientForm
        from="Client"
        ref="clientForm"
        v-model="showClientForm"
        :editMode="true"
        :clientID="clientDetails.id"
        @updateList="getClientObject"
      />
      <WebhookConfigurationForm
        v-model="showClientConfigurationForm"
        :clientID="clientDetails.id"
        @updateList="getClientWebHookConfig"
      />
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import WebhookConfigurationForm from "./WebhookConfigurationForm.vue";
import ClientForm from "./ClientForm.vue";

export default {
  name: "ClientDetails",
  components: {
    BaseDialog,
    BaseListLayout,
    ClientForm,
    WebhookConfigurationForm,
  },
  props: {
    value: Boolean,
    clientID: Number,
  },
  data() {
    return {
      showClientForm: false,
      showClientConfigurationForm: false,
      totalIntegrationPartnerApis: 0,
      clientDetails: {},
      webhookDetails: {
        customFields: [],
      },
    };
  },
  watch: {
    showClientDetails(val) {
      if (val) {
        this.getClientObject();
        this.getClientWebHookConfig();
      }
    },
  },

  computed: {
    basicDetails() {
      return [
        {
          label: "Client Name",
          value: this.clientDetails.name,
        },
        {
          label: "Client Code",
          value: this.clientDetails.code,
        },
        {
          label: "Organization Name",
          value: this.clientDetails.organization_name,
        },
        { label: "Webhook URL", value: this.clientDetails.webhook_url },
        {
          label: "Integration Partner",
          value: this.clientDetails.integration_partner_name,
        },
        {
          label: "Webhooks Sent Count",
          value: this.clientDetails?.webhook_count
            ? this.clientDetails?.webhook_count
            : 0,
        },
      ];
    },
    webhookData() {
      return [
        { label: "Status", data: this.webhookDetails.client_webhook_statuses },
        { label: "Fieldset", data: this.webhookDetails.clients_webhook_fields },
        {
          label: "Custom Fieldset",
          data: this.webhookDetails.customFields,
        },
      ];
    },
    showClientDetails: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getClientObject() {
      this.$api.client
        .getClientObject(this.clientID)
        .then((res) => {
          this.clientDetails = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getClientWebHookConfig() {
      this.$api.client
        .getClientWebHookConfig(this.clientID)
        .then((res) => {
          this.webhookDetails = res.data;
          let list = [];
          let c_list = [];
          this.webhookDetails.clients_webhook_fields.forEach((f) => {
            if (f.default_value) {
              c_list.push(f);
            } else {
              list.push(f);
            }
          });
          this.webhookDetails.clients_webhook_fields = list;
          this.webhookDetails.customFields = c_list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
