<template>
  <BaseDialog
    v-model="showWebhookConfigurationForm"
    title="Webhook Configuration"
    fullscreen
    @closeDialog="showWebhookConfigurationForm = false"
  >
    <template #dialogContent>
      <v-form
        v-model="isValid"
        ref="webhookConfigurationForm"
        id="webhookConfigurationForm"
        name="webhookConfigurationForm"
      >
        <v-row no-gutters>
          <v-col cols="6" class="pb-1">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              label="Webhook URL"
              :error-messages="
                formErrors && formErrors.webhook_url
                  ? formErrors.webhook_url
                  : ''
              "
              v-model="webhookConfigForm.webhook_url"
              @input="
                formErrors && formErrors.webhook_url
                  ? delete formErrors.webhook_url
                  : ''
              "
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pl-2">
            <v-switch
              v-model="webhookConfigForm.is_webhook_enabled"
              label="Is Webhook Enabled"
              :true-value="true"
              :false-value="false"
            >
            </v-switch>
          </v-col>
          <v-col cols="6" class="pb-3">
            <BaseSelect
              :required="true"
              label="Select Status"
              :isClearable="false"
              :itemsList="possibleOrderStatusList"
              item-text="organization_status"
              :multiple="true"
              :return-object="true"
              v-model="webhookConfigForm.client_webhook_statuses"
              :error-messages="
                formErrors && formErrors.client_webhook_statuses
                  ? formErrors.client_webhook_statuses
                  : ''
              "
              @change="
                formErrors && formErrors.client_webhook_statuses
                  ? delete formErrors.client_webhook_statuses
                  : ''
              "
            />
            <v-row
              class="mt-3"
              no-gutters
              v-for="(status, i) in webhookConfigForm.client_webhook_statuses"
              :key="i"
            >
              <v-col cols="6">
                <v-text-field
                  disabled
                  outlined
                  hide-details="auto"
                  dense
                  label="Default Label"
                  v-model="status.organization_status"
                />
              </v-col>
              <v-col cols="6" class="pl-4">
                <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  label="Custom Label"
                  v-model="status.client_status"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="pb-3 pl-2">
            <BaseSelect
              :required="true"
              label="Select Fields"
              :isClearable="false"
              :itemsList="fieldList"
              item-text="key"
              :multiple="true"
              :return-object="true"
              v-model="webhookConfigForm.clients_webhook_fields"
              :error-messages="
                formErrors && formErrors.clients_webhook_fields
                  ? formErrors.clients_webhook_fields
                  : ''
              "
              @change="
                formErrors && formErrors.clients_webhook_fields
                  ? delete formErrors.clients_webhook_fields
                  : ''
              "
            />
            <v-row
              class="mt-3"
              no-gutters
              v-for="(field, i) in webhookConfigForm.clients_webhook_fields"
              :key="i"
            >
              <v-col cols="6">
                <v-text-field
                  disabled
                  outlined
                  hide-details="auto"
                  dense
                  label="Default Label"
                  v-model="field.key"
                />
              </v-col>
              <v-col cols="6" class="pl-4">
                <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  label="Custom Label"
                  v-model="field.label_name"
                />
              </v-col>
            </v-row>

            <!-- Display Fields -->
            <v-row class="mt-3" no-gutters>
              <v-col cols="12">
                <JsonEditorVue :mode="'code'" v-model="selectedFields" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-end mr-2 mt-5">
          <v-btn color="secondary" small @click="customFields = [{}]">
            Add Meta Data
          </v-btn>
        </v-row>
        <v-row
          class="mt-2"
          no-gutters
          v-for="(custom, i) in customFields"
          :key="i"
        >
          <v-col cols="4">
            <v-text-field
              class="pl-2"
              outlined
              hide-details="auto"
              dense
              label="Label Name"
              v-model="custom.label_name"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              class="pl-2"
              outlined
              hide-details="auto"
              dense
              label="Default Value"
              v-model="custom.default_value"
            />
          </v-col>
          <v-col cols="2" class="d-flex align-center">
            <v-btn color="green" class="ml-2" depressed small @click="addItem">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              color="red"
              class="ml-2"
              depressed
              small
              @click="removeItem(i)"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        class="rounded-lg"
        small
        @click="$refs.webhookConfigurationForm.reset()"
      >
        Reset
      </v-btn>
      <v-btn
        color="primary"
        :disabled="!isValid"
        class="rounded-lg"
        small
        @click="submitForm()"
      >
        Submit
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { bus } from "@/main";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import JsonEditorVue from "vue-json-editor";

export default {
  components: { BaseDialog, BaseSelect, JsonEditorVue },
  props: {
    value: {
      type: Boolean,
    },
    clientID: {
      type: Number,
    },
  },
  data() {
    return {
      formErrors: {
        non_field_errors: [],
      },
      isValid: true,
      webhookConfigForm: {},
      fieldList: [
        { key: "WayBillNumber", label_name: "WayBillNumber" },
        { key: "CustomerName", label_name: "CustomerName" },
        { key: "CustomerCode", label_name: "CustomerCode" },
        { key: "Status", label_name: "Status" },
        { key: "Message", label_name: "Message" },
        { key: "LastLocation", label_name: "LastLocation" },
        { key: "Updated", label_name: "Updated" },
        { key: "StatusCode", label_name: "StatusCode" },
      ],
      possibleOrderStatusList: [
        { organization_status: "Pending", client_status: "Pending" },
        { organization_status: "Created", client_status: "Created" },
        {
          organization_status: "Out For Pickup",
          client_status: "Out For Pickup",
        },
        {
          organization_status: "Ready For Pickup",
          client_status: "Ready For Pickup",
        },
        { organization_status: "Picked Up", client_status: "Picked Up" },
        {
          organization_status: "Partially Picked Up",
          client_status: "Partially Picked Up",
        },
        {
          organization_status: "Pickup Failed",
          client_status: "Pickup Failed",
        },
        {
          organization_status: "In Transit",
          client_status: "In Transit",
        },
        {
          organization_status: "Hold at Hub",
          client_status: "Hold at Hub",
        },
        {
          organization_status: "Out For Delivery",
          client_status: "Out For Delivery",
        },
        {
          organization_status: "Partially Delivered",
          client_status: "Partially Delivered",
        },
        { organization_status: "Delivered", client_status: "Delivered" },
        {
          organization_status: "Delivery Failed",
          client_status: "Delivery Failed",
        },
        {
          organization_status: "Out For Return",
          client_status: "Out For Return",
        },
        {
          organization_status: "Returned",
          client_status: "Returned",
        },
        {
          organization_status: "Return Failed",
          client_status: "Return Failed",
        },
        {
          organization_status: "Cancelled",
          client_status: "Cancelled",
        },
        {
          organization_status: "Lost",
          client_status: "Lost",
        },
        {
          organization_status: "Destroyed",
          client_status: "Destroyed",
        },
        {
          organization_status: "Return to Shipper",
          client_status: "Return to Shipper",
        },
      ],
      customFields: [],

      selectedFields: [],
    };
  },

  computed: {
    showWebhookConfigurationForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    showWebhookConfigurationForm(val) {
      if (val) {
        if (this.clientID) {
          (this.fieldList = [
            { key: "WayBillNumber", label_name: "WayBillNumber" },
            { key: "CustomerName", label_name: "CustomerName" },
            { key: "CustomerCode", label_name: "CustomerCode" },
            { key: "Status", label_name: "Status" },
            { key: "Message", label_name: "Message" },
            { key: "LastLocation", label_name: "LastLocation" },
            { key: "Updated", label_name: "Updated" },
            { key: "StatusCode", label_name: "StatusCode" },
          ]),
            (this.possibleOrderStatusList = [
              { organization_status: "Pending", client_status: "Pending" },
              { organization_status: "Created", client_status: "Created" },
              {
                organization_status: "Out For Pickup",
                client_status: "Out For Pickup",
              },
              {
                organization_status: "Ready For Pickup",
                client_status: "Ready For Pickup",
              },
              { organization_status: "Picked Up", client_status: "Picked Up" },
              {
                organization_status: "Partially Picked Up",
                client_status: "Partially Picked Up",
              },
              {
                organization_status: "Pickup Failed",
                client_status: "Pickup Failed",
              },
              {
                organization_status: "In Transit",
                client_status: "In Transit",
              },
              {
                organization_status: "Hold at Hub",
                client_status: "Hold at Hub",
              },
              {
                organization_status: "Out For Delivery",
                client_status: "Out For Delivery",
              },
              {
                organization_status: "Partially Delivered",
                client_status: "Partially Delivered",
              },
              { organization_status: "Delivered", client_status: "Delivered" },
              {
                organization_status: "Delivery Failed",
                client_status: "Delivery Failed",
              },
              {
                organization_status: "Out For Return",
                client_status: "Out For Return",
              },
              {
                organization_status: "Returned",
                client_status: "Returned",
              },
              {
                organization_status: "Return Failed",
                client_status: "Return Failed",
              },
              {
                organization_status: "Cancelled",
                client_status: "Cancelled",
              },
              {
                organization_status: "Lost",
                client_status: "Lost",
              },
              {
                organization_status: "Destroyed",
                client_status: "Destroyed",
              },
              {
                organization_status: "Return to Shipper",
                client_status: "Return to Shipper",
              },
            ]);
          this.getClientWebHookConfig();
        }
      } else {
        this.resetForm();
      }
    },
  },
  methods: {
    getClientWebHookConfig() {
      this.$api.client
        .getClientWebHookConfig(this.clientID)
        .then((res) => {
          this.webhookConfigForm = res.data;
          let list = [];
          let c_list = [];
          this.webhookConfigForm.clients_webhook_fields.forEach((f) => {
            if (f.default_value) {
              c_list.push(f);
            } else {
              list.push(f);
            }
          });
          this.webhookConfigForm.clients_webhook_fields = list;
          this.customFields = c_list;

          this.selectedFields = list;
          this.selectedFields.map((fields) => {
            delete fields.id, delete fields.default_value;
          });
        })
        .catch((err) => {
          this.formErrors = err.data;
        });
    },
    resetForm() {
      const form = this.$refs.webhookConfigurationForm;
      if (form) {
        form.reset();
      }
    },
    submitForm() {
      bus.$emit("showLoader", true);
      let data = {
        ...this.webhookConfigForm,
      };
      this.customFields.forEach((c) => {
        c.key = c.label_name;
      });
      let fields = data.clients_webhook_fields.concat(this.customFields);
      data.clients_webhook_fields = fields;

      this.$api.client
        .addClientWebHookConfig(this.clientID, data)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Webhook Configuration Updated!",
            color: "success",
          });
          this.resetForm();
          this.showWebhookConfigurationForm = false;
          this.$emit("updateList");
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          this.formErrors = err.data;
        });
    },
    addItem() {
      this.customFields.push({});
    },
    removeItem(index) {
      this.customFields.splice(index, 1);
    },
  },
};
</script>