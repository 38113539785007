import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,{staticClass:"mt-0 primary d-flex align-center"},[_c(VCol,{attrs:{"cols":"10"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"text-white text-body-2 mr-1 font-weight-bold"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.trace_id)+" ")])]}}])},[_vm._v(" Trace ID ")])],1),_c(VCol,{attrs:{"cols":"1"}},[_c(VBtn,{attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.$emit('closeDetails')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_c(VRow,[_c(VCol,{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pa-0 ma-0",attrs:{"elevation":"0"}},[_c(VDivider),_c(VCardSubtitle,_vm._l((_vm.details),function(detail){return _c(VRow,{key:detail.label,attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c('h4',{staticClass:"text-caption text--primary"},[_vm._v(" "+_vm._s(detail.label)+" ")])]),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"6"}},[_c('h5',[_vm._v(_vm._s(detail.value ? detail.value : "-"))])])],1)}),1),(_vm.orderInformation && Object.keys(_vm.orderInformation).length)?_c(VCardTitle,[_c('h4',{staticClass:"text-body-2 font-weight-bold my-2"},[_vm._v("Payload")])]):_vm._e(),_c(VCardSubtitle,_vm._l((_vm.orderInformation),function(value,key,inx){return _c(VRow,{key:inx,attrs:{"no-gutters":""}},[_c(VCol,[_c('h4',{staticClass:"text-caption text-capitalize text--primary"},[_vm._v(" "+_vm._s(key.replace(/_/g, " "))+" ")])]),_c(VCol,{staticClass:"d-flex justify-end",staticStyle:{"text-overflow":"ellipsis","overflow":"hidden"}},[_c('h5',[_vm._v(_vm._s(value))])])],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }