import { inbound } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getRequestLogList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(inbound.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  viewLogDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${inbound.base}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getClientShipmentSystemLogs(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${inbound.awb_system_log}`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getClientShipmentHistoryLogs(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${inbound.awb_history_log}`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  // Bulk Resend Failed Apis Method
  bulkResendShipmentFailedApis(params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${inbound.base}bulk_resend_failed_api/`, params)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  // Single Resend Failed Apis Method
  singleResendShipmentFailedApis(id, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${inbound.base}${id}/update_and_resend_failed_api/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  // Method For Fetching Vendor Shipment Status
  fetchShipmentStatus(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${inbound.base}${id}/fetch_shipment_detail/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
