<template>
  <div>
    <v-card
      v-if="shipmentObject.category == 'Order'"
      style="background-color: #f5f8fa; height: 800px"
      elevation="0"
      class="overflow-y-auto"
    >
      <v-row>
        <v-col cols="12">
          <v-card class="rounded-xl" elevation="0">
            <v-card-title class="ma-0 primary--text">
              Shipment Timeline
            </v-card-title>
            <v-card-text>
              <v-stepper class="rounded-xl" alt-labels elevation="0">
                <v-stepper-header>
                  <v-stepper-step step="1" :complete="curr >= 1">
                    Created
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="2" :complete="curr >= 2">
                    Picked Up
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="3" :complete="curr >= 3">
                    Arrived At Hub
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="4" :complete="curr >= 4">
                    Delivered
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card elevation="0">
            <v-card-title class="ma-0 primary--text">
              Order Information
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row
                no-gutters
                v-for="detail in basicInformation"
                :key="detail.label"
              >
                <v-col cols="6">
                  {{ detail.label }}
                </v-col>
                <v-col cols="6">
                  <span class="primary--text">
                    {{ detail.value }}
                  </span>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                v-for="detail in orderInformation"
                :key="detail.label"
              >
                <v-col cols="6" class="text-capitalize">
                  {{ detail.label }}
                </v-col>
                <v-col cols="6">
                  <span class="primary--text">
                    {{ detail.value }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card elevation="0" class="mt-4">
            <v-card-title class="ma-0 primary--text">
              Payment Information & Carrier Custom Attributes
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row
                no-gutters
                v-for="(value, key) in payloadData.payment"
                :key="key"
              >
                <v-col cols="6" class="text-capitalize">
                  {{ key.replace("_", " ") }}
                </v-col>
                <v-col cols="6">
                  <span class="primary--text">
                    {{ value }}
                  </span>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                v-for="(value, key) in payloadData.carrier_custom_attributes"
                :key="key"
              >
                <v-col cols="6" class="text-capitalize">
                  {{ key.replace("_", " ") }}
                </v-col>
                <v-col cols="6">
                  <span class="primary--text">
                    {{ value }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card elevation="0">
            <v-card-title class="ma-0 primary--text"> Shipper </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row
                no-gutters
                v-for="(value, key) in payloadData.pickup"
                :key="key"
              >
                <v-col cols="4" class="text-capitalize">
                  {{ key.replace("_", " ") }}
                </v-col>
                <v-col cols="8">
                  <span class="primary--text">
                    {{ value }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="mt-4" elevation="0">
            <v-card-title class="ma-0 primary--text"> Consignee </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row
                no-gutters
                v-for="(value, key) in payloadData.dropoff"
                :key="key"
              >
                <v-col cols="4" class="text-capitalize">
                  {{ key.replace("_", " ") }}
                </v-col>
                <v-col cols="8">
                  <span class="primary--text">
                    {{ value }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <BaseListLayout
            name="packages"
            ref="packages"
            title="Order Packages"
            :table-header="itemColumns"
            :table-data="payloadData.items"
            :hasAddButton="true"
            :context="context"
            :gridStyle="{ height: '40vh' }"
            :showColumnSelection="false"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-card
      v-if="shipmentObject.category == 'Cancel Order'"
      style="background-color: #f5f8fa; height: 800px"
      elevation="0"
      class="overflow-y-auto"
    >
      <v-row>
        <v-col cols="6">
          <v-card elevation="0">
            <v-card-title class="ma-0 primary--text">
              Basic Information
            </v-card-title>
            <v-card-text>
              <v-row
                no-gutters
                v-for="detail in basicInformation"
                :key="detail.label"
              >
                <v-col cols="6">
                  {{ detail.label }}
                </v-col>
                <v-col cols="6">
                  <span class="primary--text">
                    {{ detail.value }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card elevation="0">
            <v-card-title
              class="ma-0"
              :class="
                shipmentObject.response.status_code == 400
                  ? 'red--text'
                  : 'green--text'
              "
            >
              {{ shipmentObject.response }}
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      v-if="shipmentObject.category == 'Track AWB'"
      style="background-color: #f5f8fa; height: 800px"
      elevation="0"
      class="overflow-y-auto"
    >
      <v-card class="rounded-xl mb-4" elevation="0">
        <v-card-title class="ma-0 primary--text">
          Basic Information
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            no-gutters
            v-for="detail in basicInformation"
            :key="detail.label"
          >
            <v-col cols="2">
              {{ detail.label }}
            </v-col>
            <v-col cols="2">
              <span class="primary--text">
                {{ detail.value }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="rounded-xl" elevation="0">
        <v-card-title
          v-if="shipmentObject.response.status_code == 400"
          class="ma-0 red--text"
        >
          {{ shipmentObject.response }}
        </v-card-title>
        <v-card-title v-else class="primary--text"> Response </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="4"
              no-gutters
              v-for="(res, i) in shipmentObject.response"
              :key="i"
            >
              <v-card style="background-color: #f5f8fa" elevation="0">
                <v-card-title>{{ res.awb_number }}</v-card-title>
                <v-card-text>
                  <v-timeline dense>
                    <v-timeline-item
                      v-for="(log, i) in res.awb_logs"
                      :key="i"
                      small
                    >
                      <div>
                        <v-row no-gutters>
                          <v-col cols="6">
                            <span style="font-size: 10px" class="text-caption">
                              {{ log.status }}
                            </span>
                          </v-col>
                          <v-col cols="6" class="text-right">
                            <span
                              class="font-weight-light text_color--text"
                              style="font-size: 10px"
                            >
                              {{ log.created }}
                            </span>
                          </v-col>
                          <v-col cols="12">
                            <h5 class="text_color--text text-caption">
                              {{ log.message }}
                            </h5>
                          </v-col>
                          <v-col cols="12">
                            <span style="font-size: 10px" class="text-caption">
                              Country - {{ log.country }}, Branch -
                              {{ log.current_branch }}
                            </span>
                          </v-col>
                        </v-row>
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
    <v-card
      v-if="shipmentObject.category == 'Generate AWB PDF'"
      style="background-color: #f5f8fa; height: 800px"
      elevation="0"
      class="overflow-y-auto"
    >
      <v-row> <v-col cols="12">PDF Order</v-col> </v-row>
    </v-card>
  </div>
</template>
  <script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";

export default {
  name: "CarriyoSummary",
  props: ["shipmentObject", "payloadData"],
  components: { BaseListLayout },
  data() {
    return {
      curr: 0,
      steps: [
        { name: "Created" },
        { name: "Picked Up" },
        {
          name: "Arrived At Hub",
        },
        { name: "Delivered" },
      ],
      itemColumns: [
        { headerName: "Weight Value", field: "weight.value" },
        { headerName: "Weight Unit", field: "weight.unit" },
        { headerName: "Price Amount", field: "price.amount" },
        { headerName: "Price Currency", field: "price.currency" },
        { headerName: "Quantity", field: "quantity" },
        { headerName: "SKU", field: "sku" },
        { headerName: "Description", field: "description" },
      ],
    };
  },
  watch: {
    shipmentObject(val) {
      switch (val.last_status) {
        case "Created":
          return (this.curr = 1);
        case "Picked Up":
          return (this.curr = 2);
        case "Arrived At Hub":
          return (this.curr = 3);
        case "Delivered":
          return (this.curr = 4);
        default:
          return (this.curr = 0);
      }
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    basicInformation() {
      return [
        {
          label: "Integration Partner Name",
          value: this.shipmentObject.integration_partner_name,
        },
        {
          label: "Organization",
          value: this.shipmentObject.organization_name,
        },
        {
          label: "Client",
          value: this.shipmentObject.client_name,
        },
        {
          label: "Is Success",
          value: this.shipmentObject.is_success,
        },
        {
          label: "Last AWB Status",
          value: this.shipmentObject.last_awb_status,
        },
      ];
    },
    orderInformation() {
      return [
        {
          label: "Entity Type",
          value: this.payloadData.entity_type,
        },
        {
          label: "Merchant",
          value: this.payloadData.merchant,
        },
        {
          label: "Order Date",
          value: this.payloadData.order_date,
        },
        {
          label: "Tenant",
          value: this.payloadData.tenant,
        },
      ];
    },
  },
};
</script>