<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        searchable
        ref="inboundOrderLogList"
        title="Order Logs"
        :table-header="columns"
        :table-data="tableData"
        :context="context"
        :total="totalItems"
        name="inboundOrderLogs"
        localStorageKey="orderLogsColumns"
        @selectionChanged="selectionChanged"
        @getList="getOrderLogsList"
      >
        <template #leftFilterSlot>
          <v-btn
            color="primary"
            x-small
            fab
            depressed
            class="ma-1 rounded-lg"
            @click="showFilterDialog = true"
            ><v-icon> mdi-filter</v-icon>
          </v-btn>
        </template>
        <template #dialogs>
          <ExportReportDialog
            title="Order"
            v-model="showExportDialog"
            request_type="Inbound"
            request_for="order"
          >
          </ExportReportDialog>
          <RequestedLogsFilterDialog
            title="inbound_order_logs_"
            v-model="showFilterDialog"
            @applyFilters="applyFilters"
          >
          </RequestedLogsFilterDialog>
          <JsonEditorModal
            v-model="showJsonEditorDialog"
            :payload="payload"
            :id="orderLogsId"
            @save="onSave"
            :resendErrors="resendErrors"
          >
          </JsonEditorModal>
        </template>
        <template #rightSlot>
          <v-btn
            color="primary"
            small
            class="ma-1"
            @click="showExportDialog = true"
          >
            <v-icon size="16">mdi-export</v-icon>Export Data
          </v-btn>
          <v-btn
            color="primary"
            :disabled="selectedItems.length == 0"
            small
            class="ma-1"
            @click="bulkResend()"
          >
            <v-icon size="16">mdi-upload</v-icon>Bulk Resend
          </v-btn>
        </template>
      </BaseListLayout>
    </v-col>
    <v-col
      cols="4"
      v-if="hasOrderLogsDetails"
      class="pt-0 left-devider-shadow overflow-hidden-y"
    >
      <BaseLogsDetailsLayout
        :trace_id="orderLogsObject.trace_id"
        v-model="hasOrderLogsDetails"
        :details="details"
        :payloadDetails="orderLogsObject.payload"
        @closeDetails="hasOrderLogsDetails = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ActionsButton from "@/components/AgGridButtons/ActionsButton.vue";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import BaseLogsDetailsLayout from "@/components/BaseLayoutComponents/BaseLogsDetailsLayout.vue";
import RequestedLogsFilterDialog from "@/components/common/RequestedLogsFilterDialog.vue";
import ExportReportDialog from "@/components/common/ExportReportDialog.vue";
import JsonEditorModal from "@/components/common/JsonEditorModal.vue";
import { bus } from "@/main";

export default {
  name: "OrderLogs",
  components: {
    BaseListLayout,
    ActionsButton,
    JsonEditorModal,
    CellRenderer,
    ExportReportDialog,
    BaseLogsDetailsLayout,
    RequestedLogsFilterDialog,
  },
  data() {
    return {
      canEdit: true,
      showExportDialog: false,
      showFilterDialog: false,
      hasOrderLogsDetails: false,
      showJsonEditorDialog: false,

      totalItems: 0,
      orderLogsId: null,
      payload: {},
      orderLogsObject: {},
      tableData: [],
      selectedItems: [],
      resendErrors: {},

      columns: [
        {
          headerCheckboxSelection: true,
          checkboxSelection: true,
          showDisabledCheckboxes: true,
          maxWidth: 50,
        },
        { headerName: "Trace ID", field: "trace_id" },
        {
          headerName: "Created  ",
          field: "created",
          cellRenderer: "CellRenderer",
        },
        { headerName: "Organization", field: "organization_name" },
        { headerName: "Client", field: "client_name" },
        { headerName: "Reference No.", field: "reference_no" },
        { headerName: "Method", field: "method" },
        { headerName: "Response Code", field: "status_code" },
        {
          headerName: "Success",
          field: "is_success",
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "Is Resend",
          field: "has_resend",
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionsButton",
          minWidth: 100,
        },
      ],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    details() {
      return [
        { label: "Method", value: this.orderLogsObject.method },
        {
          label: "Endpoint",
          value: this.orderLogsObject.organization_endpoint,
        },
        {
          label: "Client Name",
          value: this.orderLogsObject.client_name,
        },
        {
          label: "Status Code",
          value: this.orderLogsObject.status_code,
        },
        {
          label: "Organization",
          value: this.orderLogsObject.organization_name,
        },
        {
          label: "Status",
          value:
            this.orderLogsObject.is_success == "Yes" ? "Succeed" : "Failed",
        },
        {
          label: "Order Reference Number",
          value: this.orderLogsObject.reference_no,
        },
      ];
    },
  },
  methods: {
    getOrderLogsList(params) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem("inbound_order_logs_Filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("organization" in filters && filters != null) {
        filters.organization = filters.organization.join(",");
      }
      if ("client" in filters && filters != null) {
        filters.client = filters.client.join(",");
      }

      this.$api.inboundLogs
        .getOrderLogsList({ ...filters, ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.tableData = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    viewDetails(id) {
      this.$api.inboundLogs
        .getOrderLogsObject(id)
        .then((res) => {
          this.orderLogsObject = res.data;
          this.hasOrderLogsDetails = true;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err?.data?.detail,
            color: "red",
          });
        });
    },
    editDetails(id) {
      this.orderLogsId = id;
      this.$api.inboundLogs
        .getOrderLogsObject(id)
        .then((res) => {
          this.payload = res.data.payload;
          this.showJsonEditorDialog = true;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    onSave(payload, id) {
      this.$api.inboundLogs
        .editOrderLogsObject(payload, id)
        .then((res) => {
          this.getOrderLogsList();
          this.showJsonEditorDialog = false;
          this.$refs.inboundOrderLogList.updateList();
          bus.$emit("showToastMessage", {
            message: res.data.message,
            color: "success",
          });
        })
        .catch((err) => {
          if (typeof err?.data == "object") {
            this.resendErrors = err?.data ? err?.data : {};
          }
        });
    },
    selectionChanged(selectedRows) {
      this.selectedItems = selectedRows;
    },
    bulkResend() {
      let selectedItemsIds = [];
      this.selectedItems.map((item) => {
        selectedItemsIds.push(item.id);
      });
      this.$api.inboundLogs
        .bulkResendOrderLog({ request_ids: selectedItemsIds })
        .then((res) => {
          this.$refs.inboundOrderLogList.updateList();
          bus.$emit("showToastMessage", {
            message: res.data.message,
            color: "success",
          });
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err?.data?.detail,
            color: "red",
          });
        });
    },
    applyFilters() {
      this.$refs.inboundOrderLogList.refreshList();
    },
  },
};
</script>
