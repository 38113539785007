const logo = require("@/assets/logo.png");

const itemsPerPage = 50;

const profileImage = require("@/assets/user.png");
const choices = require("@/utils/choices.js");

export default {
  logo,
  itemsPerPage,
  profileImage,
  choices,
};
