<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        name="reportList"
        ref="reportList"
        title="Reports"
        searchable
        :table-header="columns"
        :table-data="tableData"
        :context="context"
        :total="totalItems"
        localStorageKey="reportColumns"
        @getList="getReportList"
      >
        <template #leftFilterSlot>
          <v-row no-gutters>
            <v-col cols="3" class="pr-2">
              <BaseDatePickerInput
                v-model="filters.start_date"
                id="start_date"
                name="start_date"
                label="Start Date"
                clearable
                @input="applyFilter"
              />
            </v-col>
            <v-col cols="3" class="pr-2">
              <BaseDatePickerInput
                v-model="filters.end_date"
                id="end_date"
                name="end_date"
                :min="filters.start_date"
                label="End Date"
                clearable
                @input="applyFilter"
              />
            </v-col>
            <v-col cols="2" class="pr-2">
              <BaseSelect
                :isSearchRequired="false"
                class="rounded-pill"
                label="Requested For"
                :isClearable="true"
                item-text="display_name"
                item-value="value"
                :itemsList="requestForList"
                v-model="filters.request_for"
                @change="applyFilter"
              />
            </v-col>
            <v-col cols="2" class="pr-2">
              <BaseSelect
                :isSearchRequired="false"
                class="rounded-pill"
                label="Requested Type"
                :isClearable="true"
                item-text="display_name"
                item-value="value"
                :itemsList="requestTypeList"
                v-model="filters.request_type"
                @change="applyFilter"
              />
            </v-col>
            <v-col cols="2" class="pr-2">
              <BaseSelect
                :isSearchRequired="false"
                class="rounded-pill"
                label="Status"
                :isClearable="true"
                :itemsList="statusList"
                item-text="display_name"
                item-value="value"
                v-model="filters.status"
                @change="applyFilter"
              />
            </v-col>
          </v-row>
        </template>
      </BaseListLayout>
    </v-col>
  </v-row>
</template>
  <script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ActionButton from "@/components/AgGridButtons/ActionButton.vue";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import BaseDatePickerInput from "@/components/BaseComponents/BaseDatePickerInput.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import { bus } from "@/main";

export default {
  name: "Report",
  components: {
    BaseListLayout,
    ActionButton,
    CellRenderer,
    BaseDatePickerInput,
    BaseSelect,
  },
  data() {
    return {
      fromReport: true,
      totalItems: 0,
      tableData: [],
      filters: {},
      requestForList: [],
      requestTypeList: [],
      statusList: [],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    columns() {
      return [
        { headerName: "Report ID", field: "report_id" },
        {
          headerName: "Created",
          field: "created",
          cellRenderer: "CellRenderer",
        },
        { headerName: "Requested By", field: "requested_user" },
        { headerName: "Requested Type", field: "request_type" },
        { headerName: "Requested For", field: "request_for_display" },
        { headerName: "Status", field: "status" },
        { headerName: "Total Records", field: "total_records" },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionButton",
          minWidth: 100,
        },
      ];
    },
    profile() {
      if (
        typeof window !== "undefined" &&
        localStorage &&
        localStorage.getItem("profile")
      ) {
        return JSON.parse(localStorage.getItem("profile"));
      } else {
        return {};
      }
    },
  },
  methods: {
    getReportList(params) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem("Report_Filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }

      if (this.profile.user_type == "organization") {
        params.organization = this.profile.organization;
      }

      this.$api.report
        .getReportList({ ...filters, ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.tableData = res.data.results;
        })
        .catch((err) => {
          console.log(err.data);

          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    downloadReport(data) {
      this.$api.report
        .downloadReport(data.id)
        .then((res) => {
          let url = window.URL.createObjectURL(new Blob([res.data]));

          let a = document.createElement("a");
          let fileName = `${data.report_id}.xlsx`;

          a.href = url;
          a.download = fileName;
          a.target = "_blank";
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.message,
            color: "red",
          });
        });
    },
    getOptionsList() {
      this.$api.report
        .getOptionsList()
        .then((res) => {
          this.requestForList = res?.data?.request_for;
          this.requestTypeList = res?.data?.request_type;
          this.statusList = res?.data?.status;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    applyFilter() {
      localStorage.setItem("Report_Filters", JSON.stringify(this.filters));
      this.$refs.reportList.refreshList();
    },
  },
  mounted() {
    this.getOptionsList();
    let f = localStorage.getItem("Report_Filters");
    if (f) {
      this.filters = JSON.parse(f);
    }
  },
};
</script>