<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        searchable
        ref="inboundServiceList"
        title="Service Logs"
        :table-header="columns"
        :table-data="tableData"
        :context="context"
        :total="totalItems"
        name="inboundServiceLogs"
        localStorageKey="serviceLogsColumns"
        @getList="getServicesLogsList"
      >
        <template #leftFilterSlot>
          <v-btn
            color="primary"
            x-small
            fab
            depressed
            class="ma-1 rounded-lg"
            @click="showFilterDialog = true"
            ><v-icon> mdi-filter</v-icon>
          </v-btn>
        </template>
        <template #dialogs>
          <ExportReportDialog
            title="Service"
            v-model="showExportDialog"
            request_type="Inbound"
            request_for="get_services"
          >
          </ExportReportDialog>
          <RequestedLogsFilterDialog
            title="inbound_services_logs_"
            v-model="showFilterDialog"
            @applyFilters="applyFilters"
          >
          </RequestedLogsFilterDialog>
        </template>
        <template #rightSlot>
          <v-btn
            color="primary"
            small
            class="ma-1"
            @click="showExportDialog = true"
          >
            <v-icon size="16">mdi-export</v-icon>Export Data
          </v-btn>
        </template>
      </BaseListLayout>
    </v-col>
    <v-col
      cols="4"
      v-if="hasServiceLogsDetails"
      class="pt-0 left-devider-shadow overflow-hidden-y"
    >
      <BaseLogsDetailsLayout
        :trace_id="serviceLogsObject.trace_id"
        v-model="hasServiceLogsDetails"
        :details="details"
        :payloadDetails="serviceLogsObject.payload"
        @closeDetails="hasServiceLogsDetails = false"
      />
    </v-col>
  </v-row>
</template>
  
  <script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ActionsButton from "@/components/AgGridButtons/ActionsButton.vue";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import RequestedLogsFilterDialog from "@/components/common/RequestedLogsFilterDialog.vue";
import BaseLogsDetailsLayout from "@/components/BaseLayoutComponents/BaseLogsDetailsLayout.vue";
import ExportReportDialog from "@/components/common/ExportReportDialog.vue";
import { bus } from "@/main";

export default {
  name: "ServicesLogs",
  components: {
    BaseListLayout,
    ActionsButton,
    CellRenderer,
    ExportReportDialog,
    BaseLogsDetailsLayout,
    RequestedLogsFilterDialog,
  },
  data() {
    return {
      totalItems: 0,
      canEdit: false,
      showExportDialog: false,
      showFilterDialog: false,
      hasServiceLogsDetails: false,
      serviceLogsObject: {},
      tableData: [],
      columns: [
        { headerName: "Trace ID", field: "trace_id" },
        {
          headerName: "Created",
          field: "created",
          cellRenderer: "CellRenderer",
        },
        { headerName: "Organization", field: "organization_name" },
        { headerName: "Client", field: "client_name" },
        { headerName: "Method", field: "method" },
        { headerName: "Response Code", field: "status_code" },
        {
          headerName: "Success",
          field: "is_success",
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionsButton",
          minWidth: 100,
        },
      ],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    details() {
      return [
        { label: "Method", value: this.serviceLogsObject.method },
        {
          label: "Endpoint",
          value: this.serviceLogsObject.organization_endpoint,
        },
        {
          label: "Client Name",
          value: this.serviceLogsObject.client_name,
        },
        {
          label: "Status Code",
          value: this.serviceLogsObject.status_code,
        },
        {
          label: "Organization",
          value: this.serviceLogsObject.organization_name,
        },
        {
          label: "Status",
          value:
            this.serviceLogsObject.is_success == "Yes" ? "Succeed" : "Failed",
        },
      ];
    },
  },
  methods: {
    getServicesLogsList(params) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem("inbound_services_logs_Filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("organization" in filters && filters != null) {
        filters.organization = filters.organization.join(",");
      }
      if ("client" in filters && filters != null) {
        filters.client = filters.client.join(",");
      }

      this.$api.inboundLogs
        .getServicesLogsList({ ...filters, ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.tableData = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    viewDetails(id) {
      this.$api.inboundLogs
        .getServicesLogsObject(id)
        .then((res) => {
          this.serviceLogsObject = res.data;
          this.hasServiceLogsDetails = true;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err?.data?.detail,
            color: "red",
          });
        });
    },
    applyFilters() {
      this.$refs.inboundServiceList.refreshList();
    },
  },
};
</script>
  