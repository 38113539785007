<template>
  <BaseDialog
    v-model="showOrganizationURLDetails"
    title="Organization Api Details"
    height="90vh"
    width="1100"
    @closeDialog="showOrganizationURLDetails = false"
  >
    <template #dialogContent>
      <v-row>
        <v-col cols="12">
          <v-card-title>
            <span
              style="font-size: 14px"
              class="mr-8"
              v-for="detail in basicDetails"
              :key="detail.label"
            >
              <span class="primary--text">{{ detail.label }} -</span>
              <span class="font-weight-bold secondary--text">
                {{ detail.value }}
              </span>
            </span>
          </v-card-title>
        </v-col>
      </v-row>
      <v-card elevation="0" style="background-color: #f5f8fa">
        <v-card-text>
          <v-row no-gutters class="">
            <!-- <v-col :cols="isUserTypeOrganization ? 12 : 8">
              <BaseListLayout
                title="Incoming Request Log"
                ref="incomingRequestLogsRef"
                :table-header="incomingReqLogs"
                :table-data="incomingReqLogsList"
                :context="context"
                :total="totalItems"
                name="incomingRequestLogsRef"
                @getList="getIncomingRequestLogs"
                :gridStyle="gridStyle"
                :showColumnSelection="false"
              >
              </BaseListLayout>
            </v-col> -->
            <v-col cols="12" class="px-2">
              <div
                class="pb-4 text-lg-subtitle-1 text-uppercase font-weight-bold text--primary"
              >
                Schema
              </div>
              <JsonEditorVue
                :mode="'code'"
                style="background-color: white"
                v-model="organizationApiDetails.schema"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </BaseDialog>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ActionsButton from "@/components/AgGridButtons/ActionsButton.vue";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import JsonEditorVue from "vue-json-editor";
import { getUserProfile } from "@/utils/functions.js";
import { bus } from "@/main";

export default {
  name: "OrganizationApiDetails",
  components: {
    BaseListLayout,
    ActionsButton,
    CellRenderer,
    JsonEditorVue,
    BaseDialog,
  },
  props: {
    value: Boolean,
    organizationApiDetails: Object,
  },
  data() {
    return {
      totalItems: 10,
      incomingReqLogsList: [],
      gridStyle: {
        width: "100%",
        height: "70vh",
      },
      incomingReqLogs: [
        { headerName: "Trace ID", field: "trace_id" },
        {
          headerName: "Created Date",
          field: "created",
          cellRenderer: "CellRenderer",
        },
        { headerName: "Client", field: "client_name" },
        { headerName: "Method", field: "method" },
        { headerName: "Response Code", field: "status_code" },
        {
          headerName: "Success",
          field: "is_success",
          cellRenderer: "CellRenderer",
        },
      ],
    };
  },
  // watch: {
  //   showOrganizationURLDetails(val) {
  //     if (val) {
  //       let params = { offset: 0, limit: 10 };
  //       this.getIncomingRequestLogs(params);
  //     }
  //   },
  // },
  computed: {
    context() {
      return { parentComponent: this };
    },
    isUserTypeOrganization() {
      return getUserProfile().user_type == "organization";
    },
    showOrganizationURLDetails: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    basicDetails() {
      return [
        {
          label: "Organization",
          value: this.organizationApiDetails.organization_name,
        },
        {
          label: "Request For",
          value: this.organizationApiDetails.request_for,
        },
        {
          label: "URL",
          value: this.organizationApiDetails.url,
        },
        {
          label: "Success API Count",
          value: this.organizationApiDetails.success_api_count,
        },
        {
          label: "Failed API Count",
          value: this.organizationApiDetails.failed_api_count,
        },
      ];
    },
  },
  methods: {
    applyFilters() {
      this.$refs.incomingRequestLogsRef.refreshList();
    },
    getIncomingRequestLogs(params) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem(
        `Incoming_Logs_${this.organizationApiDetails.request_for}`
      );
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("client" in filters && filters != null) {
        filters.client = filters.client.join(",");
      }

      params = {
        organization: this.organizationApiDetails.organization,
        ...filters,
        ...params,
      };
      if (this.organizationApiDetails.request_for == "Order") {
        this.$api.inboundLogs
          .getOrderLogsList(params)
          .then((res) => {
            bus.$emit("showLoader", false);
            this.totalItems = res.count;
            this.incomingReqLogsList = res.data.results;
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: err.data.detail,
              color: "red",
            });
          });
      } else if (this.organizationApiDetails.request_for == "Cancel Order") {
        this.$api.inboundLogs
          .getCancelOrderLogsList(params)
          .then((res) => {
            bus.$emit("showLoader", false);
            this.totalItems = res.count;
            this.incomingReqLogsList = res.data.results;
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: err.data.detail,
              color: "red",
            });
          });
      } else if (
        this.organizationApiDetails.request_for == "Generate AWB PDF"
      ) {
        this.$api.inboundLogs
          .getGenerateAWBPdfLogsList(params)
          .then((res) => {
            bus.$emit("showLoader", false);
            this.totalItems = res.count;
            this.incomingReqLogsList = res.data.results;
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: err.data.detail,
              color: "red",
            });
          });
      } else if (
        this.organizationApiDetails.request_for == "Get Instructions"
      ) {
        this.$api.inboundLogs
          .getInstructionsLogsList(params)
          .then((res) => {
            bus.$emit("showLoader", false);
            this.totalItems = res.count;
            this.incomingReqLogsList = res.data.results;
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: err.data.detail,
              color: "red",
            });
          });
      } else if (this.organizationApiDetails.request_for == "Get Locations") {
        this.$api.inboundLogs
          .getLocationsLogsList(params)
          .then((res) => {
            bus.$emit("showLoader", false);
            this.totalItems = res.count;
            this.incomingReqLogsList = res.data.results;
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: err.data.detail,
              color: "red",
            });
          });
      } else if (this.organizationApiDetails.request_for == "Order Pricing") {
        this.$api.inboundLogs
          .getOrderPricingLogsList(params)
          .then((res) => {
            bus.$emit("showLoader", false);
            this.totalItems = res.count;
            this.incomingReqLogsList = res.data.results;
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: err.data.detail,
              color: "red",
            });
          });
      } else if (this.organizationApiDetails.request_for == "Get Products") {
        this.$api.inboundLogs
          .getProductsLogsList(params)
          .then((res) => {
            bus.$emit("showLoader", false);
            this.totalItems = res.count;
            this.incomingReqLogsList = res.data.results;
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: err.data.detail,
              color: "red",
            });
          });
      } else if (this.organizationApiDetails.request_for == "Get Services") {
        this.$api.inboundLogs
          .getServicesLogsList(params)
          .then((res) => {
            bus.$emit("showLoader", false);
            this.totalItems = res.count;
            this.incomingReqLogsList = res.data.results;
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: err.data.detail,
              color: "red",
            });
          });
      } else if (this.organizationApiDetails.request_for == "Track AWB") {
        this.$api.inboundLogs
          .getTrackAWBLogsList(params)
          .then((res) => {
            bus.$emit("showLoader", false);
            this.totalItems = res.count;
            this.incomingReqLogsList = res.data.results;
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: err.data.detail,
              color: "red",
            });
          });
      } else if (this.organizationApiDetails.request_for == "Get Areas") {
        this.$api.inboundLogs
          .getAreasLogsList(params)
          .then((res) => {
            bus.$emit("showLoader", false);
            this.totalItems = res.count;
            this.incomingReqLogsList = res.data.results;
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: err.data.detail,
              color: "red",
            });
          });
      }
    },
  },
};
</script>
