<template>
  <div class="login-main">
    <v-row style="width: 100% !important; height: 100vh">
      <v-col cols="12" class="d-flex justify-center align-center">
        <div class="py-12 pl-10 d-flex justify-center align-center">
          <v-card
            class="px-12 py-12 align-center"
            style="max-width: 450px; min-width: 350px"
          >
            <v-card-title class="d-flex justify-center">
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="pa-0 ma-0 d-flex justify-center">
                  <v-img
                    :src="$globalConstant.logo"
                    contain
                    style="height: 50%; width: 50%"
                  ></v-img>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-form
                v-model="isValid"
                ref="loginForm"
                @submit.prevent="submitForm"
              >
                <v-row class="justify-center">
                  <v-col cols="pt-8 pb-6 ma-0">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <v-text-field
                          v-model.trim="userDetails.username"
                          prepend-inner-icon="mdi-account-circle"
                          label="Username"
                          :rules="[(v) => !!v || 'Username is required']"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          label="Password"
                          v-model="userDetails.password"
                          prepend-inner-icon="mdi-lock-outline"
                          :append-icon="oldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="oldPassword ? 'text' : 'password'"
                          :rules="[(v) => !!v || 'Password is required']"
                          @click:append="oldPassword = !oldPassword"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn
                      id="loginBtn"
                      color="secondary"
                      class="mt-4"
                      block
                      @click="onSubmit()"
                      :disabled="!isValid"
                      customClass="rounded-lg"
                    >
                      Login
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { bus } from "@/main";
export default {
  name: "mainLogin",
  layout: "authentication",
  data() {
    return {
      username: "",
      password: "",
      oldPassword: false,
      userDetails: {},
      formErrors: {},
      isValid: true,
      error: {
        username: null,
        password: null,
      },
    };
  },
  methods: {
    onSubmit() {
      bus.$emit("showLoader", true);
      this.$api.auth
        .login(this.userDetails)
        .then((res) => {
          bus.$emit("showLoader", false);
          localStorage.setItem("user", JSON.stringify(res?.data?.detail));
          this.setProfile();
          // this.$router.push("/dashboard");
        })
        .catch((res) => {
          bus.$emit("showLoader", false);
          if (res && res?.data?.errors) {
            bus.$emit("showToastMessage", {
              message: res?.data?.errors[0]?.error_message,
              color: "error",
            });
          }
        });
    },
    setProfile() {
      this.$api.user
        .getUserList({ limit: "all" })
        .then((res) => {
          res.data.forEach((user) => {
            if (user.username == this.userDetails.username) {
              localStorage.setItem("profile", JSON.stringify(user));
              this.$router.push("/dashboard");
            }
          });
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
  },
};
</script>

<style lang="scss">
.container {
  width: 100% !important;
}
.login-main {
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .login-main {
    display: flex;
    align-items: flex-end;
    height: 100vh;
  }
}
.login-card {
  padding: 0px 48px;
  min-height: 80vh;
}

@media screen and (max-width: 1400px) {
  .login-card {
    padding: 0px 28px;
  }
}
@media screen and (max-width: 768px) {
  .login-card {
    // min-height: 50px;
    padding: 0px 8px 40px 8px;
    margin-bottom: 10%;
  }
}

input {
  background-color: white !important;
}

.custom-shadow-login {
  box-shadow: 0px 0px 47px -20px rgba(5, 5, 5, 0.5),
    0px -2px 12px -10px rgba(5, 5, 5, 0.3) !important;
}
.login-bg-image {
  position: fixed;
  height: 100vh !important;
  width: 100vw !important;
}
.login-title {
  text-align: center;
  text-transform: uppercase;
  font-weight: 100 !important;
  padding: 40px 0px;
  font-size: 38px;
  margin: 32px 0;
}

@media screen and (max-width: 1400px) {
  .login-title {
    font-size: 26px !important ;
    margin: 0px 0;
  }
}
@media screen and (max-width: 768px) {
  .login-title {
    font-size: 30px !important ;
    margin: 0px 0;
  }
}

.login-hr {
  width: 30%;
  margin-top: 24px;
  margin-bottom: 36px;
  background-color: red;
}

@media screen and (max-width: 1400px) {
  .login-hr {
    margin-top: 14px;
    margin-bottom: 6px;
  }
}
.login-logo-image {
  height: 120px;
}
.login-btn {
  text-transform: uppercase !important;
}
</style>
