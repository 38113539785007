<template>
  <v-row class="ma-0 overflow-y-auto" style="height: 600px">
    <v-col cols="9" class="mt-3">
      <v-row no-gutters>
        <v-col cols="4">
          <v-card class="rounded-xl" elevation="0">
            <v-card-title class="ma-0 primary--text">
              Basic Information
            </v-card-title>
            <v-card-text>
              <v-row
                no-gutters
                v-for="detail in basicDetails"
                :key="detail.label"
              >
                <v-col cols="6">
                  {{ detail.label }}
                </v-col>
                <v-col cols="6">
                  <span class="primary--text">
                    {{ detail.value }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4" class="ml-5">
          <v-card class="rounded-xl" elevation="0">
            <v-card-title class="ma-0 primary--text">
              Shipment Address
            </v-card-title>
            <v-card-text>
              <v-row
                no-gutters
                v-for="addr in toAddressDetails"
                :key="addr.label"
              >
                <v-col cols="4">
                  {{ addr.label }}
                </v-col>
                <v-col cols="8">
                  <span class="primary--text">
                    {{ addr.value }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters class="mt-3">
        <v-col cols="4">
          <v-card class="rounded-xl" elevation="0">
            <v-card-title class="ma-0 primary--text">
              Additional Information
            </v-card-title>
            <v-card-text>
              <v-row
                no-gutters
                v-for="detail in additionalDetails"
                :key="detail.label"
              >
                <v-col cols="4">
                  {{ detail.label }}
                </v-col>
                <v-col cols="8">
                  <span class="primary--text">
                    {{ detail.value }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4" class="ml-5">
          <v-card class="rounded-xl" elevation="0">
            <v-card-title class="ma-0 primary--text">
              Parcel Information
            </v-card-title>
            <v-card-text>
              <v-row
                no-gutters
                v-for="detail in parcelDetails"
                :key="detail.label"
              >
                <v-col cols="4">
                  {{ detail.label }}
                </v-col>
                <v-col cols="8">
                  <span class="primary--text">
                    {{ detail.value }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="3" class="mt-3 ma-0 pa-0">
      <v-card class="rounded-xl" elevation="0">
        <v-card-title class="ma-0 primary--text">
          Shipment Activity
        </v-card-title>
        <v-card-text>
          <v-timeline dense side="end" light align-top>
            <v-timeline-item
              v-for="(event, i) in shipmentActivity"
              :key="i"
              small
              fill-dot
            >
              <div class="text-black">
                <div class="font-weight-normal">
                  <strong>{{ event.details }}</strong>
                </div>
                <div class="text-caption">
                  <v-icon small>mdi-calendar-clock </v-icon>
                  {{ event.status }}
                </div>
                <div class="text-caption">
                  <v-icon small>mdi-map-marker</v-icon>
                  {{ event.time }}
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { convertDateTime, formatLastUpdated } from "@/utils/functions";

export default {
  name: "ATSAwbTrack",
  components: {
    convertDateTime,
  },
  props: {
    vendorDetails: Object,
    trackingDetailObject: Object,
  },
  data() {
    return {};
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    requestData() {
      return this.vendorDetails.request_data;
    },
    trackResponseShipment() {
      return this.trackingDetailObject.Shipment;
    },
    shipmentActivity() {
      return this.trackResponseShipment?.Activity;
    },
    basicDetails() {
      return [
        {
          label: "Organization",
          value: this.vendorDetails.organization,
        },
        {
          label: "AWB Number",
          value: this.requestData?.AwbNumber,
        },
        {
          label: "Vendor AWB Status",
          value: this.vendorDetails.vendor_awb_status,
        },
        {
          label: "Tracking Number",
          value: this.vendorDetails.track_no,
        },
        {
          label: "Current Status",
          value: this.trackResponseShipment?.current_status,
        },
        {
          label: "Organization Awb Status",
          value: this.vendorDetails?.organization_awb_status,
        },
        {
          label: "Last Update Time",
          value: this.vendorDetails?.last_updated_timestamp
            ? convertDateTime(this.vendorDetails?.last_updated_timestamp) +
              " (" +
              formatLastUpdated(this.vendorDetails?.last_updated_timestamp) +
              ")"
            : null,
        },
        {
          label: "Status Datetime",
          value: this.trackResponseShipment?.status_datetime,
        },
        {
          label: "Last Status Sent To Organization",
          value: this.vendorDetails.last_status_sent_to_org
            ? this.vendorDetails.last_status_sent_to_org
            : "N/A",
        },
        {
          label: "Last Update Sent To Org At",
          value: this.vendorDetails.last_update_sent_to_org_at
            ? convertDateTime(this.vendorDetails.last_update_sent_to_org_at) +
              " (" +
              formatLastUpdated(this.vendorDetails.last_update_sent_to_org_at) +
              ")"
            : null,
        },
        {
          label: "Last Update Received At",
          value: this.vendorDetails.last_update_received_at
            ? convertDateTime(this.vendorDetails.last_update_received_at) +
              " (" +
              formatLastUpdated(this.vendorDetails.last_update_received_at) +
              ")"
            : null,
        },
      ];
    },
    additionalDetails() {
      return [
        {
          label: "Reference Number",
          value: this.requestData?.ReferenceNumber,
        },
        {
          label: "Ncnd Amount",
          value: this.requestData?.NcndAmount,
        },
        {
          label: "Branch Name",
          value: this.requestData?.BranchName,
        },
        {
          label: "Currency Code",
          value: this.requestData?.CurrencyCode,
        },
      ];
    },
    parcelDetails() {
      return [
        {
          label: "Parcel Weight",
          value: this.requestData?.Weight + " KG",
        },
        {
          label: "Pieces",
          value: this.requestData?.Pieces,
        },
        {
          label: "Package Type",
          value: this.requestData?.PackageType,
        },
        {
          label: "Description",
          value: this.requestData?.ItemDescription,
        },
      ];
    },
    toAddressDetails() {
      return [
        {
          label: "Company",
          value: this.requestData?.ToCompany,
        },
        {
          label: "Address",
          value: this.requestData?.ToAddress,
        },
        {
          label: "Location",
          value: this.requestData?.ToLocation,
        },
        {
          label: "Country",
          value: this.requestData?.ToCountry,
        },
        {
          label: "City",
          value: this.requestData?.ToCity,
        },
        {
          label: "Contact Person",
          value: this.requestData?.ToCperson,
        },
        {
          label: "Contact Number",
          value: this.requestData?.ToContactno,
        },
        {
          label: "Mobile Number",
          value: this.requestData?.ToMobileno,
        },
      ];
    },
  },
  methods: {
    convertEventTimeStamp(value) {
      return convertDateTime(value);
    },
  },
};
</script>

<style></style>
