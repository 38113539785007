<template>
  <v-row>
    <v-col cols="12">
      <!----------Organization Buttons Start------------->
      <v-menu offset-y v-if="params.context.parentComponent.fromOrganization">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" x-small v-bind="attrs" v-on="on">
            <v-icon> mdi-dots-horizontal </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item value="View Details" @click="viewDetails()">
            <v-icon small class="mr-2"> mdi-eye </v-icon>
            <v-list-item-title>View Details</v-list-item-title>
          </v-list-item>

          <v-list-item value="Edit Details" @click="editDetails()">
            <v-icon small class="mr-2"> mdi-pencil </v-icon>
            <v-list-item-title>Edit Details</v-list-item-title>
          </v-list-item>

          <v-list-item value="View Api List" @click="openOrganizationApiPage()">
            <v-icon small class="mr-2"> mdi-api </v-icon>
            <v-list-item-title>View Api</v-list-item-title>
          </v-list-item>

          <v-list-item
            value="View Vendor List"
            @click="openOrganizationVendorPage()"
          >
            <v-icon small class="mr-2"> mdi-account-multiple </v-icon>
            <v-list-item-title>View Vendors</v-list-item-title>
          </v-list-item>

          <!-- <v-list-item
            value="View Client List"
            @click="openOrganizationClientPage()"
          >
            <v-icon small class="mr-2"> mdi-account </v-icon>
            <v-list-item-title>View Clients</v-list-item-title>
          </v-list-item> -->

          <v-list-item value="Configuration" @click="openConfiguration()">
            <v-icon small class="mr-2"> mdi-account-cog </v-icon>
            <v-list-item-title>Configuration</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-------------Organization Buttons End------------->

      <!----------Vendor Buttons Start------------->
      <v-btn
        color="primary"
        v-if="params.context.parentComponent.fromVendor"
        class="mr-1"
        x-small
        depressed
        @click="editVendorDetails()"
      >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>

      <!------------Vendor Buttons End--------------->

      <!----------Client Buttons Start------------->
      <v-menu offset-y v-if="params.context.parentComponent.fromClient">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" x-small v-bind="attrs" v-on="on">
            <v-icon> mdi-dots-horizontal </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item value="View Details" @click="viewDetails()">
            <v-icon small class="mr-2"> mdi-eye </v-icon>
            <v-list-item-title>View Details</v-list-item-title>
          </v-list-item>
          <v-list-item value="Edit Details" @click="editDetails()">
            <v-icon small class="mr-2"> mdi-pencil </v-icon>
            <v-list-item-title>Edit Details</v-list-item-title>
          </v-list-item>
          <v-list-item
            value="Configure Webhook"
            @click="openClientConfigurationForm()"
          >
            <v-icon small class="mr-2"> mdi-account-cog </v-icon>
            <v-list-item-title>Configure Webhook</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- -------Client Buttons End--------------->

      <!-- -------User Buttons Start--------------->
      <v-btn
        color="primary"
        v-if="params.context.parentComponent.fromUser"
        x-small
        depressed
        @click="editDetails()"
      >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      <!-- -------User Buttons End--------------->

      <!----------Report Buttons Start------------->
      <v-btn
        color="primary"
        v-if="
          params.context.parentComponent.fromReport &&
          params.data.total_records > 0
        "
        class="mr-1"
        x-small
        depressed
        @click="downloadReport()"
      >
        <v-icon small>mdi-download</v-icon>
      </v-btn>
      <!-- -------Report Buttons End--------------->

      <!----------Inbound Shipment Details Buttons Start------------->
      <v-btn
        text
        v-if="
          params.context.parentComponent.fromInboundShipmentDetails ||
          params.context.parentComponent.fromVendorShipmentDetails
        "
        @click="moreDetails()"
      >
        <v-icon color="primary">mdi-open-in-new</v-icon>
      </v-btn>
      <!-- -------Inbound Shipment Details Buttons End--------------->

      <!----------Organization Configuration Buttons Start------------->
      <v-btn
        color="primary"
        x-small
        depressed
        v-if="params.context.parentComponent.fromOrganizationConfiguration"
        @click="editDetails()"
      >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      <!-- -------Organization Configuration Buttons End--------------->
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    editDetails() {
      this.params.context.parentComponent.editDetails(this.params.data.id);
    },
    viewDetails() {
      this.params.context.parentComponent.viewDetails(this.params.data.id);
    },
    openOrganizationApiPage() {
      this.params.context.parentComponent.openOrganizationApiPage(
        this.params.data.id,
        this.params.data.name
      );
    },
    openOrganizationVendorPage() {
      this.params.context.parentComponent.openOrganizationVendorPage(
        this.params.data.id,
        this.params.data.name
      );
    },
    openOrganizationClientPage() {
      this.params.context.parentComponent.openOrganizationClientPage(
        this.params.data.id,
        this.params.data.name
      );
    },
    openClientConfigurationForm() {
      this.params.context.parentComponent.openClientConfigurationForm(
        this.params.data.id,
        this.params.data.name
      );
    },
    downloadReport() {
      this.params.context.parentComponent.downloadReport(this.params.data);
    },
    editVendorDetails() {
      this.params.context.parentComponent.editVendorDetails(
        this.params.data.id
      );
    },
    moreDetails() {
      this.params.context.parentComponent.moreDetails(this.params.data.payload);
    },
    openConfiguration() {
      this.params.context.parentComponent.openConfiguration(
        this.params.data.id,
        this.params.data.name
      );
    },
  },
};
</script>