import { inboundLogs } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  //Webhook Logs
  getOrganizationWebhookLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(inboundLogs.webhook, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrganizationWebhookLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${inboundLogs.webhook}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkResendOrganizationWebhookLog(params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${inboundLogs.webhook}resend_bulk_request/`, params)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  // editOrganizationWebhookLogsObject(params, id) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(`${inboundLogs.webhook}${id}/resend_request/`, params)
  //       .then((res) => {
  //         resolve(handleResponse(res));
  //       })
  //       .catch((err) => {
  //         reject(handleError(err));
  //       });
  //   });
  // },

  //Order Logs
  getOrderLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(inboundLogs.order, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrderLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${inboundLogs.order}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  editOrderLogsObject(payload, id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${inboundLogs.order}${id}/resend_request/`, { payload: payload })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkResendOrderLog(params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${inboundLogs.order}resend_bulk_request/`, params)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //Order Cancel
  getCancelOrderLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(inboundLogs.cancelOrder, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkResendCancelOrderLog(params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${inboundLogs.cancelOrder}resend_bulk_request/`, params)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCancelOrderLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${inboundLogs.cancelOrder}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  editCancelOrderLogsObject(payload, id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${inboundLogs.cancelOrder}${id}/resend_request/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //Track AWB
  getTrackAWBLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(inboundLogs.trackAWB, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTrackAWBLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${inboundLogs.trackAWB}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //Generate AWB PDF Logs
  getGenerateAWBPdfLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(inboundLogs.generateAWBPdf, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getGenerateAWBPdfLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${inboundLogs.generateAWBPdf}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  editGenerateAWBPdfObject(payload, id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${inboundLogs.generateAWBPdf}${id}/resend_request/`, {
          payload: payload,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //Order Pricing Logs
  getOrderPricingLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(inboundLogs.orderPricing, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrderPricingLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${inboundLogs.orderPricing}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  editOrderPricingObject(payload, id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${inboundLogs.orderPricing}${id}/resend_request/`, {
          payload: payload,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //Products Logs
  getProductsLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(inboundLogs.products, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getProductsLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${inboundLogs.products}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //Services Logs
  getServicesLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(inboundLogs.services, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getServicesLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${inboundLogs.services}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //Instructions Logs
  getInstructionsLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(inboundLogs.instructions, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getInstructionsLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${inboundLogs.instructions}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //Locations Logs
  getLocationsLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(inboundLogs.locations, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getLocationsLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${inboundLogs.locations}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //Areas Logs
  getAreasLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(inboundLogs.areas, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getAreasLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${inboundLogs.areas}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
