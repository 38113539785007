<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        searchable
        ref="outboundAreaList"
        title="Area Logs"
        :table-header="columns"
        :table-data="tableData"
        :context="context"
        :total="totalItems"
        name="outboundAreaLogs"
        localStorageKey="outBoundAreaLogsColumns"
        @getList="getAreasLogsList"
      >
        <template #leftFilterSlot>
          <v-btn
            x-small
            color="primary"
            fab
            depressed
            class="ma-1 rounded-lg"
            @click="showFilterDialog = true"
            ><v-icon> mdi-filter</v-icon>
          </v-btn>
        </template>
        <template #dialogs>
          <ExportReportDialog
            title="Areas"
            v-model="showExportDialog"
            request_type="Outbound"
            request_for="get_areas"
          >
          </ExportReportDialog>
          <RequestedLogsFilterDialog
            title="outbound_areas_logs_"
            v-model="showFilterDialog"
            @applyFilters="applyFilters"
          >
          </RequestedLogsFilterDialog>
        </template>
        <template #rightSlot>
          <v-btn
            color="primary"
            small
            class="ma-1"
            @click="showExportDialog = true"
          >
            <v-icon size="16">mdi-export</v-icon>Export Data
          </v-btn>
        </template>
      </BaseListLayout>
    </v-col>
    <v-col
      cols="4"
      v-if="hasAreaLogsDetails"
      class="pt-0 left-devider-shadow overflow-hidden-y"
    >
      <BaseLogsDetailsLayout
        :trace_id="areaLogsObject.trace_id"
        v-model="hasAreaLogsDetails"
        :details="details"
        :payloadDetails="areaLogsObject.payload"
        @closeDetails="hasAreaLogsDetails = false"
      />
    </v-col>
  </v-row>
</template>
  
  <script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ActionsButton from "@/components/AgGridButtons/ActionsButton.vue";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import BaseLogsDetailsLayout from "@/components/BaseLayoutComponents/BaseLogsDetailsLayout.vue";
import RequestedLogsFilterDialog from "@/components/common/RequestedLogsFilterDialog.vue";
import ExportReportDialog from "@/components/common/ExportReportDialog.vue";
import { bus } from "@/main";

export default {
  name: "AreasLogs",
  components: {
    BaseListLayout,
    ActionsButton,
    CellRenderer,
    ExportReportDialog,
    BaseLogsDetailsLayout,
    RequestedLogsFilterDialog,
  },
  data() {
    return {
      totalItems: 0,
      canEdit: false,
      showExportDialog: false,
      showFilterDialog: false,
      hasAreaLogsDetails: false,
      areaLogsObject: {},
      tableData: [],
      columns: [
        { headerName: "Trace ID", field: "trace_id" },
        {
          headerName: "Created",
          field: "created",
          cellRenderer: "CellRenderer",
        },
        { headerName: "Organization", field: "organization_name" },
        { headerName: "Method", field: "method" },
        { headerName: "Response Code", field: "status_code" },
        {
          headerName: "Success",
          field: "is_success",
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionsButton",
          minWidth: 100,
        },
      ],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    details() {
      return [
        { label: "Method", value: this.areaLogsObject.method },
        {
          label: "Endpoint",
          value: this.areaLogsObject.organization_endpoint,
        },
        {
          label: "Status Code",
          value: this.areaLogsObject.status_code,
        },
        {
          label: "Organization",
          value: this.areaLogsObject.organization_name,
        },
        {
          label: "Status",
          value: this.areaLogsObject.is_success == "Yes" ? "Succeed" : "Failed",
        },
      ];
    },
  },
  methods: {
    getAreasLogsList(params) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem("outbound_areas_logs_Filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("organization" in filters && filters != null) {
        filters.organization = filters.organization.join(",");
      }
      if ("client" in filters && filters != null) {
        filters.client = filters.client.join(",");
      }
      this.$api.outboundLogs
        .getAreasLogsList({ ...filters, ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.tableData = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    viewDetails(id) {
      this.$api.outboundLogs
        .getAreasLogsObject(id)
        .then((res) => {
          this.areaLogsObject = res.data;
          this.hasAreaLogsDetails = true;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err?.data?.detail,
            color: "red",
          });
        });
    },
    applyFilters() {
      this.$refs.outboundAreaList.refreshList();
    },
  },
};
</script>
  