<template>
  <div>
    <v-card style="background-color: #f5f8fa" elevation="0">
      <v-card-text class="pa-1">
        <v-icon @click="$router.back()">mdi-chevron-left</v-icon>
        <span class="pl-2"> Go Back to Shipments </span>
      </v-card-text>
    </v-card>
    <v-card elevation="0">
      <v-card-title class="ma-0 pb-0">
        <span class="pl-2 font-weight-bold">
          Vendor AWB -
          {{ vendorShipmentObject.vendor_awb }}
          <span class="secondary--text">
            ({{ vendorShipmentObject.organization_vendor_name }})
          </span>
        </span>
        <v-spacer></v-spacer>
        <!-- <span class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2"
                color="secondary"
                small
                depressed
                v-bind="attrs"
                v-on="on"
                @click="fetchVendorShipmentStatus()"
              >
                <v-icon small>mdi-reload</v-icon>
              </v-btn>
            </template>
            <span>Fetch Status</span>
          </v-tooltip>
        </span> -->
      </v-card-title>
      <v-card-text>
        <span class="pl-2">
          Shipment AWB - {{ vendorShipmentObject.order_awb }}
        </span>
        <v-tabs fixed-tabs v-model="currentTab">
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab
            v-for="item in items"
            :key="item"
            style="height: 60%"
            class="mr-2 grey rounded-pill white--text"
            value="one"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </v-card-text>
    </v-card>
    <v-card style="background-color: #f5f8fa" elevation="0">
      <v-card-text>
        <v-tabs-items v-model="currentTab">
          <v-tab-item>
            <v-card style="background-color: #f5f8fa" elevation="0">
              <v-row>
                <v-col cols="12">
                  <v-card class="rounded-xl" elevation="0">
                    <v-card-title class="ma-0 primary--text">
                      Shipment Timeline
                    </v-card-title>
                    <v-card-text>
                      <v-stepper class="rounded-xl" alt-labels elevation="0">
                        <v-stepper-header>
                          <v-stepper-step step="1" :complete="curr > 1">
                            Created
                          </v-stepper-step>
                          <v-divider></v-divider>
                          <v-stepper-step step="2" :complete="curr > 2">
                            Picked Up
                          </v-stepper-step>
                          <v-divider></v-divider>
                          <v-stepper-step step="3" :complete="curr > 3">
                            Arrived At Hub
                          </v-stepper-step>
                          <v-divider></v-divider>
                          <v-stepper-step step="4" :complete="curr > 4">
                            Delivered
                          </v-stepper-step>
                        </v-stepper-header>
                      </v-stepper>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <SHLAwbTrack
                  v-if="
                    vendorShipmentObject &&
                    vendorShipmentObject.organization_vendor_name == 'SHL'
                  "
                  :vendorDetails="vendorShipmentObject"
                  :trackingDetailObject="trackingDetails"
                />
                <DHLAwbTrack
                  v-if="
                    vendorShipmentObject &&
                    vendorShipmentObject.organization_vendor_name == 'DHL'
                  "
                  :vendorDetails="vendorShipmentObject"
                  :trackingDetails="
                    trackingDetails.shipments
                      ? trackingDetails.shipments[0]
                      : {}
                  "
                />
                <SKPAwbTrack
                  v-if="
                    vendorShipmentObject &&
                    vendorShipmentObject.organization_vendor_name ==
                      'Skip Express'
                  "
                  :vendorDetails="vendorShipmentObject"
                  :trackingDetailObject="trackingDetails ? trackingDetails : {}"
                />
                <ATSAwbTrack
                  v-if="
                    vendorShipmentObject &&
                    vendorShipmentObject.organization_vendor_name == 'ATS'
                  "
                  :vendorDetails="vendorShipmentObject"
                  :trackingDetailObject="trackingDetails"
                />
                <GFSAwbTrack
                  v-if="
                    vendorShipmentObject &&
                    vendorShipmentObject.organization_vendor_name == 'GFS'
                  "
                  :vendorDetails="vendorShipmentObject"
                  :trackingDetails="trackingDetails[0]"
                />
              </v-row>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <div v-if="!historyLogs.length" class="py-3 d-flex justify-center">
              No History Found
            </div>
            <v-timeline v-else dense>
              <v-timeline-item
                color="secondary"
                v-for="item in historyLogs"
                :key="item.id"
              >
                <v-alert class="py-2 mb-1" color="grey lighten-2" :value="true">
                  <v-row>
                    <v-col cols="10">
                      {{ item.message }}
                    </v-col>
                    <v-col cols="2" class="d-flex">
                      {{
                        item.created
                          ? convertEventTimeStamp(item.created)
                          : null
                      }}
                    </v-col>
                  </v-row>
                </v-alert>
              </v-timeline-item>
            </v-timeline>
          </v-tab-item>
          <v-tab-item>
            <BaseListLayout
              name="systemList"
              ref="systemList"
              title="System Logs"
              :table-header="systemColumns"
              :table-data="systemLogs"
              :hasAddButton="true"
              :context="context"
              :gridStyle="gridStyle"
              :showColumnSelection="false"
              :total="totalItems"
              @getList="getVendorShipmentSystemLogs"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <BaseDialog
      v-model="showPayloadPreview"
      width="60vh"
      title="Payload"
      @closeDialog="showPayloadPreview = false"
    >
      <template #dialogContent>
        <div id="pdf-content">
          <pre>{{ payload }}</pre>
        </div>
      </template>
      <template #actions>
        <v-btn small text>
          <v-icon color="primary" @click="copyPayload">
            mdi-content-copy
          </v-icon>
        </v-btn>
      </template>
    </BaseDialog>
  </div>
</template>
<script>
import { bus } from "@/main";
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout.vue";
import ShipmentCellRenderer from "@/components/AgGridButtons/ShipmentCellRenderer.vue";
import ActionButton from "@/components/AgGridButtons/ActionButton.vue";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import DHLAwbTrack from "./DHLAwbTrack";
import SHLAwbTrack from "./SHLAwbTrack";
import SKPAwbTrack from "./SKPAwbTrack";
import ATSAwbTrack from "./ATSAwbTrack";
import GFSAwbTrack from "./GFSAwbTrack";
import { convertDateTime } from "@/utils/functions";

export default {
  name: "Shipments",
  components: {
    BaseListLayout,
    SHLAwbTrack,
    DHLAwbTrack,
    SKPAwbTrack,
    ATSAwbTrack,
    GFSAwbTrack,
    ShipmentCellRenderer,
    ActionButton,
    BaseDialog,
  },
  data() {
    return {
      totalItems: 0,
      currentTab: null,
      items: ["Summary", "History", "System Logs"],
      payload: {},
      showPayloadPreview: false,
      fromVendorShipmentDetails: true,

      gridStyle: {
        width: "100%",
        height: "60vh",
      },
      curr: 3,
      steps: [
        { name: "Created" },
        { name: "Picked Up" },
        {
          name: "Arrived At Hub",
        },
        { name: "Delivered" },
      ],

      vendorShipmentObject: {},
      trackingDetails: {},
      historyLogs: [],
      systemLogs: [],
      systemColumns: [
        { headerName: "Vendor Awb", field: "vendor_awb", maxWidth: 200 },
        { headerName: "Message", field: "message" },
        {
          headerName: "Is Success",
          field: "is_success",
          cellRenderer: "ShipmentCellRenderer",
          maxWidth: 150,
        },
        { headerName: "Status Code", field: "status_code", maxWidth: 150 },
        {
          headerName: "More Details",
          field: "actions",
          cellRenderer: "ActionButton",
          maxWidth: 150,
        },
      ],
    };
  },
  watch: {
    currentTab(val) {
      if (val == 0) {
        this.viewVendorShipment();
      } else if (val == 1) {
        this.getVendorShipmentHistoryLogs();
      } else if (val == 2) {
        // this.getVendorShipmentSystemLogs();
      }
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    convertEventTimeStamp(value) {
      return convertDateTime(value);
    },
    viewVendorShipment() {
      let id = this.$route.query.awb
        ? this.$route.query.awb
        : this.params.query.id;
      this.$api.vendorShipments
        .viewVendorShipment(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.vendorShipmentObject = res.data;
          this.trackingDetails = res.data.tracking_details;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    getVendorShipmentSystemLogs(params = {}) {
      params = { vendor_request_log: this.$route.query.id };
      this.$api.vendorShipments
        .getVendorShipmentSystemLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.systemLogs = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "error fetching list",
            color: "red",
          });
        });
    },
    getVendorShipmentHistoryLogs(params = {}) {
      params = { vendor_request_log: this.$route.query.id };
      this.$api.vendorShipments
        .getVendorShipmentHistoryLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.historyLogs = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "error fetching list",
            color: "red",
          });
        });
    },
    moreDetails(payload) {
      this.payload = payload;
      this.showPayloadPreview = true;
    },
    copyPayload() {
      if (this.payload) {
        navigator.clipboard.writeText(JSON.stringify(this.payload));
        bus.$emit("showToastMessage", {
          message: "Payload Copied Successfully..",
          color: "green",
        });
      }
    },
    fetchVendorShipmentStatus() {
      bus.$emit("showLoader", true);

      let params = {
        vendor_awb: this.vendorShipmentObject.vendor_awb,
        organization_awb_status:
          this.vendorShipmentObject.last_status_sent_to_org,
      };

      this.$api.vendorShipments
        .fetchVendorShipmentStatus(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: res.data.message,
            color: "success",
          });
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
  },
};
</script>
<style scoped>
.v-tab--active {
  background-color: #17b7d0 !important;
}
</style>