import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{staticClass:"ma-0"},[_c(VCol,{staticClass:"q-pa-md relative-position"},[_c('BaseListLayout',{ref:"shipmentList",attrs:{"name":"shipmentList","hasTabs":true,"title":"Shipments","searchable":"","table-header":_vm.currentTabValue == 'order'
          ? _vm.orderColumns
          : _vm.currentTabValue == 'order_cancel'
          ? _vm.cancelOrderColumns
          : _vm.trackOrderColumns,"table-data":_vm.shipmentList,"rowHeight":40,"context":_vm.context,"total":_vm.totalItems,"localStorageKey":"shipmentColumns"},on:{"selectionChanged":_vm.selectionChanged,"getList":_vm.getRequestLogList},scopedSlots:_vm._u([{key:"rightSlot",fn:function(){return [_c(VBtn,{staticClass:"ma-1",attrs:{"color":"primary","small":""},on:{"click":function($event){_vm.showExportDialog = true}}},[_c(VIcon,{attrs:{"size":"16"}},[_vm._v("mdi-export")]),_vm._v(" Export Data ")],1),_c(VBtn,{staticClass:"ma-1",attrs:{"disabled":_vm.selectedItems.length == 0,"small":"","color":"primary"},on:{"click":function($event){return _vm.bulkResend()}}},[_c(VIcon,{attrs:{"size":"16"}},[_vm._v("mdi-upload")]),_vm._v("Bulk Resend ")],1)]},proxy:true},{key:"leftFilterSlot",fn:function(){return [_c('div',[_c('FilterPanel',{attrs:{"filter_for":"inbound"},on:{"applyFilters":_vm.applyFilter}})],1)]},proxy:true},{key:"dialogs",fn:function(){return [_c('ExportReportDialog',{attrs:{"title":"Shipment Request Logs","request_type":"Inbound","request_for":"order"},model:{value:(_vm.showExportDialog),callback:function ($$v) {_vm.showExportDialog=$$v},expression:"showExportDialog"}})]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }