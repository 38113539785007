<template>
  <v-dialog
    v-model="showFilterDialog"
    @submit="applyFilters()"
    @reset="resetFilters()"
    width="33%"
    ><v-card>
      <v-card-title>
        <h3 class="text-body-1 text-capitalize text--primary font-weight-bold">
          {{ title.replace(/_/g, " ") }}Filter
        </h3>
        <v-spacer></v-spacer>
        <v-icon @click="showFilterDialog = false"> mdi-close </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4">
        <v-form ref="requestedFilterForm">
          <v-row no-gutters>
            <v-col cols="12" md="6" lg="6" xl="6" class="pt-1 pl-1 pb-3">
              <BaseDatePickerInput
                v-model="filters.start_date"
                id="start_date"
                name="start_date"
                label="Start Date"
                clearable
              ></BaseDatePickerInput>
            </v-col>
            <v-col cols="12" md="6" lg="6" xl="6" class="pt-1 pl-1 pb-3">
              <BaseDatePickerInput
                v-model="filters.end_date"
                id="end_date"
                name="end_date"
                label="End Date"
                clearable
                :min="filters.start_date"
              ></BaseDatePickerInput>
            </v-col>
            <v-col
              v-if="!isUserTypeOrganization"
              cols="12"
              md="12"
              class="pl-1 pb-3"
            >
              <BaseSelect
                label="Organization"
                :isClearable="true"
                :itemsList="organizationList"
                item-text="name"
                item-value="id"
                multiple
                v-model="filters.organization"
              />
            </v-col>
            <v-col
              v-if="title.substring(0, 7) == 'inbound'"
              cols="12"
              md="12"
              class="pl-1 pb-3"
            >
              <BaseSelect
                label="Client"
                :isClearable="true"
                :itemsList="clientList"
                item-text="name"
                item-value="id"
                multiple
                v-model="filters.client"
              />
            </v-col>
            <v-col
              v-if="title.substring(0, 6) == 'vendor'"
              cols="12"
              md="12"
              class="pl-1 pb-3"
            >
              <BaseSelect
                label="Vendor"
                :isClearable="true"
                :itemsList="vendorList"
                item-text="display_value"
                item-value="value"
                multiple
                v-model="filters.vendor"
              />
            </v-col>
            <v-col cols="6" md="6" class="pl-1 pb-3">
              <BaseSelect
                label="Status"
                :isClearable="true"
                :itemsList="$globalConstant.choices.default.statusOption"
                item-text="label"
                item-value="value"
                v-model="filters.is_success"
              />
            </v-col>
            <v-col cols="6" md="6" class="pl-1 pb-3">
              <BaseSelect
                label="Status Code"
                :isClearable="true"
                item-text="label"
                item-value="value"
                :itemsList="$globalConstant.choices.default.statusCodeOption"
                v-model="filters.status_code"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="primary"
          small
          class="rounded-lg"
          id="resetFilter"
          @click="resetFilters()"
          >Reset
        </v-btn>
        <v-btn
          color="primary"
          small
          class="rounded-lg"
          id="applyFilter"
          @click="applyFilters()"
        >
          Apply
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseDatePickerInput from "@/components/BaseComponents/BaseDatePickerInput.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import { getUserProfile } from "@/utils/functions.js";

export default {
  components: { BaseDatePickerInput, BaseSelect },
  props: {
    title: String,
    value: Boolean,
  },
  data() {
    return {
      filters: {},
      organizationList: [],
      clientList: [],
      vendorList: [],
    };
  },
  watch: {
    showFilterDialog(value) {
      if (value) {
        this.getOrganizationOptions();
        this.getClientList();
        this.getOrganizationVendorList();
        let filters = localStorage.getItem(this.title + "Filters");
        if (!filters) {
          filters = {};
        }
        if (typeof filters == typeof "string") {
          filters = JSON.parse(filters);
        }
        this.filters = filters;
      }
    },
  },
  computed: {
    showFilterDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isUserTypeOrganization() {
      return getUserProfile().user_type == "organization";
    },
  },
  methods: {
    getOrganizationOptions() {
      this.$api.organization
        .getOrganizationOptions()
        .then((res) => {
          this.organizationList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getClientList() {
      this.$api.client
        .getClientList({ limit: "all" })
        .then((res) => {
          this.clientList = res?.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getOrganizationVendorList() {
      this.$api.organizationVendor
        .getVendorOptions({ request_type: "form" })
        .then((res) => {
          this.vendorList = res.data.vendor;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    applyFilters() {
      localStorage.setItem(
        this.title + "Filters",
        JSON.stringify(this.filters)
      );
      this.$emit("applyFilters");
      this.showFilterDialog = false;
    },
    resetFilters() {
      this.filters = {};
      localStorage.removeItem(this.title + "Filters");
      this.$emit("applyFilters");
      this.showFilterDialog = false;
      if (this.$refs.requestedFilterForm) {
        this.$refs.requestedFilterForm.reset();
      }
    },
  },
};
</script>
