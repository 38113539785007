export const version = "/api/v1/";
export const v2 = "/api/v2/";

export const auth = {
  token: `${version}token/`,
  refresh: `${version}token/refresh/`,
};

export const dashboard = {
  base: `${version}dashboard/`,
};

export const user = {
  base: `${version}user/`,
};

export const organization = {
  base: `${version}organization/`,
  optionList: `${version}organization/option_list`,
  generateApi: `${version}organization/generate_api_token`,
  config: `${v2}organization-configuration/`,
};

export const client = {
  base: `${version}client/`,
};

export const organizationURL = {
  base: `${version}organization-url/`,
};

export const organizationVendor = {
  base: `${version}organization_vendor/`,
  v2Base: `${v2}organization_vendor/`,
};
export const vendor = {
  base: `${v2}vendors/`,
  vendor_api: `${v2}vendor_api/`,
  vendor_metadata: `${v2}vendor_metadata/`,
};

export const userNotification = {
  base: `${version}user-notification/`,
};

export const report = {
  base: `${version}report/`,
  // download: `${version}report/download/`,
};

export const inbound = {
  base: `${v2}request_log/`,
  awb_system_log: `${v2}awb_system_log/`,
  awb_history_log: `${v2}awb_history_log/`,
};
export const outbound = {
  base: `${version}organization/`,
};
export const vendorShipments = {
  base: `${version}vendor_shipment_tracking_log/`,
  system_logs: `${v2}vendor_awb_system_log/`,
  history_logs: `${v2}vendor_awb_history_log/`,
  view_details: `${v2}vendor_awb/`,
  fetchStatus: `${v2}vendor_awb_track/`
};

export const webhookUpdate = {
  base: `${version}awb/`,
  resend_webhook_update: `${version}awb/resend_webhook_update/`,
};

export const inboundLogs = {
  webhook: `${version}organization-webhook-log/`,
  order: `${version}client-order-log/`,
  cancelOrder: `${version}client-order-cancel-log/`,
  trackAWB: `${version}client-awb-tracking-log/`,
  generateAWBPdf: `${version}client-awb-pdf-log/`,
  orderPricing: `${version}client-order-price-log/`,
  products: `${version}client-product-log/`,
  services: `${version}client-service-log/`,
  instructions: `${version}client-instruction-log/`,
  locations: `${version}client-location-log/`,
  areas: `${version}client-area-log/`,
};

export const outboundLogs = {
  webhook: `${version}client-webhook-log/`,
  order: `${version}order-log/`,
  cancelOrder: `${version}order-cancel-log/`,
  trackAWB: `${version}awb-tracking-log/`,
  generateAWBPdf: `${version}awb-pdf-log/`,
  orderPricing: `${version}order-price-log/`,
  products: `${version}product-log/`,
  services: `${version}service-log/`,
  instructions: `${version}instruction-log/`,
  locations: `${version}location-log/`,
  areas: `${version}area-log/`,
};

// export const clientRequestLogs = {
//   base: `${version}client-request-log/`,
// };

// export const organizationRequestLogs = {
//   base: `${version}organization-request-log/`,
// };

// export const clientWebhookLogs = {
//   base: `${version}client-webhook-log/`,
// };

// export const organizationWebhookLogs = {
//   base: `${version}organization-webhook-log/`,
// };

export const awbTrackAndTrace = {
  base: `${version}awb-logs/`,
};

export const vendorAPIRequestLogs = {
  shipmentTracking: `${version}vendor_tracking/`,
  // hubToOrganization: `${version}hub_to_org_request_log/`,
  // hubToVendor: `${version}hub_to_vendor_request_log/`,
  // organizationToHub: `${version}org_to_hub_request_log/`,
};

export const clientApiIntegrationPartner = {
  base: `${version}client_api_integration_partner/`,
};

export const integrationPartner = {
  base: `${version}integration_partner/`,
  integrationPartnerApi: `${version}integration_partner_api/`,
};

export const vendorAwbTrack = {
  base: `${version}vendor_awb_logs/`,
};
