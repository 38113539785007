<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        name="organizationToHubLogs"
        ref="organizationToHubList"
        title="Organization To Hub Logs"
        searchable
        :table-header="columns"
        :table-data="tableData"
        :context="context"
        :total="totalItems"
        localStorageKey="organizationToHubColumns"
        @getList="getOrganizationToHubLogsList"
      >
        <template #leftFilterSlot>
          <v-btn
            x-small
            fab
            depressed
            color="primary"
            class="ma-1 rounded-lg"
            @click="showFilterDialog = true"
            ><v-icon> mdi-filter</v-icon>
          </v-btn>
        </template>
        <template #dialogs>
          <ExportReportDialog
            title="Organization To Hub"
            v-model="showExportDialog"
            request_type="OrganizationToHub"
            request_for="organization_to_hub"
          >
          </ExportReportDialog>
          <RequestedLogsFilterDialog
            title="organization_to_hub_"
            v-model="showFilterDialog"
            @applyFilters="applyFilters"
          >
          </RequestedLogsFilterDialog>
        </template>
        <template #rightSlot>
          <v-btn
            color="primary"
            small
            class="ma-1"
            @click="showExportDialog = true"
          >
            <v-icon size="16">mdi-export</v-icon>Export Data
          </v-btn>
        </template>
      </BaseListLayout>
    </v-col>
    <v-col
      cols="4"
      v-if="hasHubToVendorLogsDetails"
      class="pt-0 left-devider-shadow overflow-hidden-y"
    >
      <BaseLogsDetailsLayout
        :trace_id="hubToVendorLogsObject.trace_id"
        v-model="hasHubToVendorLogsDetails"
        :details="hubToVendorLogsObject.tracking_logs"
        :payloadDetails="hubToVendorLogsObject.payload"
        @closeDetails="hasHubToVendorLogsDetails = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ActionsButton from "@/components/AgGridButtons/ActionsButton.vue";
import BaseLogsDetailsLayout from "@/components/BaseLayoutComponents/BaseLogsDetailsLayout.vue";
import RequestedLogsFilterDialog from "@/components/common/RequestedLogsFilterDialog.vue";
import ExportReportDialog from "@/components/common/ExportReportDialog.vue";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import { bus } from "@/main";

export default {
  name: "OrganizationToHubLogs",
  components: {
    BaseListLayout,
    ActionsButton,
    ExportReportDialog,
    BaseLogsDetailsLayout,
    RequestedLogsFilterDialog,
    CellRenderer,
  },
  data() {
    return {
      totalItems: 0,
      canEdit: false,
      showExportDialog: false,
      showFilterDialog: false,
      hasHubToVendorLogsDetails: false,
      hubToVendorLogsObject: {},
      tableData: [],
      columns: [
        { headerName: "Tracking Number", field: "track_no" },
        {
          headerName: "Created  ",
          field: "created",
          cellRenderer: "CellRenderer",
        },
        { headerName: "Organization", field: "organization_name" },
        { headerName: "Request For", field: "request_for" },
        { headerName: "Vendor AWB Number", field: "vendor_awb" },
        { headerName: "Request Type", field: "request_type" },
        {
          headerName: "Success",
          field: "is_success",
          cellRenderer: "CellRenderer",
        },
        // {
        //   headerName: "Actions",
        //   field: "actions",
        //   cellRenderer: "ActionsButton",
        //   minWidth: 100,
        // },
      ],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    details() {
      return [];
    },
  },
  methods: {
    getOrganizationToHubLogsList(params) {
      bus.$emit("showLoader", true);

      let apiParams = {
        ...params,
      };

      let filters = localStorage.getItem("organization_to_hub_Filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("organization" in filters && filters != null) {
        filters.organization = filters.organization.join(",");
      }
      if ("vendor" in filters && filters != null) {
        filters.vendor = filters.vendor.join(",");
      }

      this.$api.vendorAPIRequestLogs
        .getOrganizationToHubLogsList({
          ...filters,
          ...apiParams,
        })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          if (res.data && res.data.results) {
            this.tableData = res.data.results;
          }
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    viewDetails(id) {
      this.$api.vendorAPIRequestLogs
        .getHubToOrganizationLogsObject(id)
        .then((res) => {
          this.hubToVendorLogsObject = res.data;
          this.hasHubToVendorLogsDetails = true;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err?.data?.detail,
            color: "red",
          });
        });
    },
    applyFilters() {
      this.$refs.organizationToHubList.refreshList();
    },
  },
};
</script>

<style></style>
