<template>
  <BaseDialog
    v-model="showOrganizationDetails"
    title="Organization Details"
    fullscreen
    @closeDialog="showOrganizationDetails = false"
  >
    <template #dialogContent>
      <v-card style="background-color: #f5f8fa" elevation="0" class="ma-0 pa-0">
        <v-card-title>
          <v-row>
            <v-col cols="2">Incoming Request Log</v-col>
            <v-col cols="10" class="d-flex justify-end">
              <span
                class="mr-8"
                style="font-size: 12px"
                v-for="detail in basicDetails"
                :key="detail.label"
              >
                {{ detail.label }} -
                <span class="secondary--text">{{ detail.value }} </span>
              </span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <BaseListLayout
            ref="organizationOrderLogsList"
            :table-header="orderColumns"
            :table-data="organizationOrderLogsList"
            :context="context"
            :total="totalOrders"
            name="organizationOrderLogsList"
            @getList="getOrganizationOrderLogsList"
            :gridStyle="gridStyle"
            :showColumnSelection="false"
          />
        </v-card-text>
        <!-- </v-card-text> -->
      </v-card>
    </template>
  </BaseDialog>
</template>
   

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import { bus } from "@/main";

export default {
  name: "OrganizationDetails",
  components: {
    BaseListLayout,
    CellRenderer,
    BaseDialog,
  },
  props: {
    value: Boolean,
    organizationDetails: Object,
  },
  data() {
    return {
      totalOrders: 0,
      organizationOrderLogsList: [],
      gridStyle: {
        width: "100%",
        height: "70vh",
      },
      orderColumns: [
        { headerName: "Trace ID", field: "trace_id" },
        {
          headerName: "Created Date",
          field: "created",
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "Success",
          field: "is_success",
          cellRenderer: "CellRenderer",
        },
      ],
    };
  },
  watch: {
    showOrganizationDetails(val) {
      if (val) {
        let params = { offset: 0, limit: 10 };
        this.getOrganizationOrderLogsList(params);
      } else {
      }
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    showOrganizationDetails: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    basicDetails() {
      return [
        {
          label: "Organization Name",
          value: this.organizationDetails.name,
        },
        {
          label: "Organization Code",
          value: this.organizationDetails.code,
        },
        { label: "Organization Api", value: this.organizationDetails.url },
        {
          label: "Webhook Log Count",
          value: this.organizationDetails.webhook_log_count,
        },
      ];
    },
  },
  methods: {
    getOrganizationOrderLogsList(params) {
      bus.$emit("showLoader", true);
      params = {
        organization: this.organizationDetails.id,
        ...params,
      };
      this.$api.inboundLogs
        .getOrderLogsList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalOrders = res.count;
          this.organizationOrderLogsList = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
  },
};
</script>
