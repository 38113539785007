<template>
  <v-row class="ma-0 overflow-y-auto" style="height: 600px">
    <v-col cols="4">
      <v-card class="rounded-xl" elevation="0">
        <v-card-title class="ma-0 primary--text">
          Basic Information
        </v-card-title>
        <v-card-text>
          <v-row no-gutters v-for="detail in basicDetails" :key="detail.label">
            <v-col cols="6">
              {{ detail.label }}
            </v-col>
            <v-col cols="6">
              <span class="primary--text">
                {{ detail.value }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="3">
      <v-card class="rounded-xl" elevation="0">
        <v-card-title class="ma-0 primary--text"> Event </v-card-title>
        <v-card-text>
          <v-timeline dense side="end" light align-top>
            <v-timeline-item
              v-for="(event, i) in trackingDetailObject.events"
              :key="i"
              small
              fill-dot
            >
              <div class="text-black">
                <div class="font-weight-normal">
                  <strong>{{ event.hub_name }}</strong>
                </div>
                <div class="text-caption">
                  <v-icon small>mdi-calendar-clock </v-icon>
                  {{ event.customer_update }}
                </div>
                <div class="text-caption">
                  <v-icon small>mdi-map-marker</v-icon>
                  {{ convertEventTimeStamp(event.event_time) }}
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { convertDateTime, formatLastUpdated } from "@/utils/functions";

export default {
  name: "SkipExpressAwbTrack",
  components: {},
  props: {
    vendorDetails: Object,
    trackingDetailObject: Object,
  },
  data() {
    return {};
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    basicDetails() {
      return [
        {
          label: "Organization",
          value: this.vendorDetails.organization,
        },
        {
          label: "Vendor AWB Status",
          value: this.vendorDetails.vendor_awb_status,
        },
        {
          label: "Tracking Number",
          value: this.vendorDetails.track_no,
        },
        {
          label: "Status",
          value: this.trackingDetailObject.status,
        },
        {
          label: "Creation Date",
          value: this.trackingDetailObject?.creation_date
            ? convertDateTime(this.trackingDetailObject.creation_date) +
              " (" +
              formatLastUpdated(this.trackingDetailObject.creation_date) +
              ")"
            : null,
        },
        {
          label: "Last Status Sent To Organization",
          value: this.vendorDetails.last_status_sent_to_org
            ? this.vendorDetails.last_status_sent_to_org
            : "N/A",
        },
        {
          label: "Reference Number",
          value: this.trackingDetailObject.reference_number,
        },
        {
          label: "Customer Reference Number",
          value: this.trackingDetailObject.customer_reference_number,
        },
        {
          label: "Receiver Name",
          value: this.trackingDetailObject.receiver_name,
        },
        {
          label: "Receiver Relation",
          value: this.trackingDetailObject.receiver_relation,
        },
        {
          label: "Service Type",
          value: this.trackingDetailObject.service_type_id,
        },
        {
          label: "COD Amount",
          value: this.trackingDetailObject.cod_amount,
        },
        {
          label: "Weight",
          value: this.trackingDetailObject.weight,
        },
        {
          label: "Delivery Date",
          value: this.trackingDetailObject.delivery_date,
        },
        {
          label: "Last Update Sent To Org At",
          value: this.vendorDetails.last_update_sent_to_org_at
            ? convertDateTime(this.vendorDetails.last_update_sent_to_org_at) +
              " (" +
              formatLastUpdated(this.vendorDetails.last_update_sent_to_org_at) +
              ")"
            : null,
        },
        {
          label: "Last Update Received At",
          value: this.vendorDetails.last_update_received_at
            ? convertDateTime(this.vendorDetails.last_update_received_at) +
              " (" +
              formatLastUpdated(this.vendorDetails.last_update_received_at) +
              ")"
            : null,
        },
      ];
    },
  },
  methods: {
    convertEventTimeStamp(value) {
      return convertDateTime(value);
    },
  },
};
</script>

<style>
</style>