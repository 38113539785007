<template>
  <v-row class="ma-0 overflow-y-auto" style="height: 600px">
    <v-col cols="4">
      <v-card class="rounded-xl" elevation="0">
        <v-card-title class="ma-0 primary--text">
          Basic Information
        </v-card-title>
        <!-- <v-divider></v-divider> -->
        <v-card-text>
          <v-row no-gutters v-for="detail in basicDetails" :key="detail.label">
            <v-col cols="6">
              {{ detail.label }}
            </v-col>
            <v-col cols="6">
              <span class="primary--text">
                {{ detail.value }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-4 rounded-xl" elevation="0">
        <v-card-title class="ma-0 primary--text">
          Status Information
        </v-card-title>
        <v-card-text>
          <v-row no-gutters v-for="status in statusDetails" :key="status.label">
            <v-col cols="4">
              {{ status.label }}
            </v-col>
            <v-col cols="8">
              <span class="primary--text">
                {{ status.value }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="5">
      <v-card class="rounded-xl" elevation="0">
        <v-card-title class="ma-0 primary--text"> Origin </v-card-title>
        <!-- <v-divider></v-divider> -->
        <v-card-text>
          <v-row no-gutters v-for="origin in originAddress" :key="origin.label">
            <v-col cols="4">
              {{ origin.label }}
            </v-col>
            <v-col cols="8">
              <span class="primary--text">
                {{ origin.value }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-4 rounded-xl" elevation="0">
        <v-card-title class="ma-0 primary--text"> Destination </v-card-title>
        <!-- <v-divider></v-divider> -->
        <v-card-text>
          <v-row
            no-gutters
            v-for="destination in destinationAddress"
            :key="destination.label"
          >
            <v-col cols="4">
              {{ destination.label }}
            </v-col>
            <v-col cols="8">
              <span class="primary--text">
                {{ destination.value }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-4 rounded-xl" elevation="0">
        <v-card-title class="ma-0 primary--text">
          Proof Of Delivery
        </v-card-title>
        <v-card-text>
          <v-row no-gutters v-for="pod in podDetails" :key="pod.label">
            <v-col cols="4">
              {{ pod.label }}
            </v-col>
            <v-col cols="8">
              <span class="primary--text">
                {{ pod.value }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="3">
      <v-card class="rounded-xl" elevation="0">
        <v-card-title class="ma-0 primary--text"> Events </v-card-title>
        <v-card-text>
          <v-timeline dense side="end" light align-top>
            <v-timeline-item
              v-for="(event, i) in trackingDetails.events"
              :key="i"
              small
              fill-dot
            >
              <div class="text-black">
                <div class="font-weight-normal">
                  <strong>{{ event.description }}</strong>
                </div>
                <div class="text-caption">
                  <v-icon small>mdi-calendar-clock </v-icon>
                  {{ event.timestamp }}
                </div>
                <div class="text-caption">
                  <v-icon small>mdi-map-marker</v-icon>
                  {{ event.location.address.addressLocality }}
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { formatLastUpdated, convertDateTime } from "@/utils/functions";

export default {
  name: "DHLAwbTrack",
  components: {
    convertDateTime,
  },
  props: {
    vendorDetails: Object,
    trackingDetails: Object,
  },
  data() {
    return {};
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    basicDetails() {
      return [
        {
          label: "Organization",
          value: this.vendorDetails.organization,
        },
        {
          label: "Vendor AWB Status",
          value: this.vendorDetails.vendor_awb_status,
        },
        {
          label: "Tracking Number",
          value: this.vendorDetails.track_no,
        },
        {
          label: "Organization Awb Status",
          value: this.vendorDetails.organization_awb_status,
        },
        {
          label: "Last Status Sent To Organization",
          value: this.vendorDetails.last_status_sent_to_org
            ? this.vendorDetails.last_status_sent_to_org
            : "N/A",
        },
        {
          label: "Last Update Sent To Org At",
          value: this.vendorDetails.last_update_sent_to_org_at
            ? convertDateTime(this.vendorDetails.last_update_sent_to_org_at) +
              " (" +
              formatLastUpdated(this.vendorDetails.last_update_sent_to_org_at) +
              ")"
            : null,
        },
        {
          label: "Last Update Received At",
          value: this.vendorDetails.last_update_received_at
            ? convertDateTime(this.vendorDetails.last_update_received_at) +
              " (" +
              formatLastUpdated(this.vendorDetails.last_update_received_at) +
              ")"
            : null,
        },
      ];
    },
    originAddress() {
      return [
        {
          label: "Country Code",
          value: this.trackingDetails?.origin?.address?.countryCode,
        },
        {
          label: "Postal Code",
          value: this.trackingDetails?.origin?.address?.postalCode,
        },
        {
          label: "Address Locality",
          value: this.trackingDetails?.origin?.address?.addressLocality,
        },
      ];
    },
    destinationAddress() {
      return [
        {
          label: "Country Code",
          value: this.trackingDetails?.destination?.address?.countryCode,
        },
        {
          label: "Postal Code",
          value: this.trackingDetails?.destination?.address?.postalCode,
        },
        {
          label: "Address Locality",
          value: this.trackingDetails?.destination?.address?.addressLocality,
        },
      ];
    },
    statusDetails() {
      return [
        {
          label: "Status",
          value:
            this.trackingDetails &&
            this.trackingDetails.status &&
            this.trackingDetails.status.status
              ? this.trackingDetails.status.status
              : null,
        },
        {
          label: "Status Code",
          value:
            this.trackingDetails &&
            this.trackingDetails.status &&
            this.trackingDetails.status.statusCode
              ? this.trackingDetails.status.statusCode
              : null,
        },
        {
          label: "Description",
          value:
            this.trackingDetails &&
            this.trackingDetails.status &&
            this.trackingDetails.status.description
              ? this.trackingDetails.status.description
              : null,
        },
        {
          label: "Remark",
          value:
            this.trackingDetails &&
            this.trackingDetails.status &&
            this.trackingDetails.status.remark
              ? this.trackingDetails.status.remark
              : null,
        },
        {
          label: "timestamp",
          value:
            this.trackingDetails &&
            this.trackingDetails.status &&
            this.trackingDetails.status.timestamp
              ? convertDateTime(this.trackingDetails.status.timestamp)
              : null,
        },
      ];
    },
    podDetails() {
      return [
        {
          label: "Document Url",
          value: this.trackingDetails.details?.proofOfDelivery?.documentUrl,
        },
        {
          label: "Signature Url",
          value: this.trackingDetails.details?.proofOfDelivery?.signatureUrl,
        },
      ];
    },
  },
  methods: {},
};
</script>

<style>
</style>