<template>
  <BaseDialog
    v-model="showEditForm"
    title="Edit Shipment"
    fullscreen
    customClass="pa-0"
    @closeDialog="showEditForm = false"
  >
    <template #dialogContent>
      <v-form v-model="isValid" ref="orderForm" id="orderForm" name="orderForm">
        <v-card elevation="0" style="background-color: #f5f8fa">
          <v-card-title> Booking Information </v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="2" class="pa-1">
                <v-text-field
                  class="rounded-lg"
                  outlined
                  hide-details="auto"
                  dense
                  label="Shipper Reference Number"
                  :error-messages="
                    formErrors && formErrors.shipper_reference_number
                      ? formErrors.shipper_reference_number
                      : ''
                  "
                  v-model="orderForm.shipper_reference_number"
                  @input="
                    formErrors && formErrors.shipper_reference_number
                      ? delete formErrors.shipper_reference_number
                      : ''
                  "
                />
              </v-col>
              <v-col cols="2" class="pa-1">
                <v-text-field
                  class="rounded-lg"
                  outlined
                  hide-details="auto"
                  dense
                  label="Awb Number"
                  disabled
                  :error-messages="
                    formErrors && formErrors.awb_number
                      ? formErrors.awb_number
                      : ''
                  "
                  v-model="orderForm.awb_number"
                  @input="
                    formErrors && formErrors.awb_number
                      ? delete formErrors.awb_number
                      : ''
                  "
                />
              </v-col>
              <v-col cols="2" class="pa-1">
                <v-text-field
                  outlined
                  class="rounded-lg"
                  hide-details="auto"
                  dense
                  label="Customer*"
                  v-model="orderForm.customer"
                  :rules="[(val) => !!val || 'Customer is required']"
                  :error-messages="
                    formErrors && formErrors.customer ? formErrors.customer : ''
                  "
                  @input="
                    formErrors && formErrors.customer
                      ? delete formErrors.customer
                      : ''
                  "
                />
              </v-col>
              <v-col cols="2" class="pa-1">
                <BaseSelect
                  :required="true"
                  class="rounded-lg"
                  label="Service Type*"
                  :is-search-required="false"
                  :isClearable="false"
                  :itemsList="['Domestic', 'Import', 'Export']"
                  v-model="orderForm.service_type"
                  :rules="[(val) => !!val || 'Service Type is required']"
                  :error-messages="
                    formErrors && formErrors.service_type
                      ? formErrors.service_type
                      : ''
                  "
                  @change="
                    formErrors && formErrors.service_type
                      ? delete formErrors.service_type
                      : ''
                  "
                />
              </v-col>
              <v-col cols="2" class="pa-1">
                <v-text-field
                  outlined
                  class="rounded-lg"
                  hide-details="auto"
                  dense
                  label="Product*"
                  v-model="orderForm.product"
                  :rules="[(val) => !!val || 'Product is required']"
                  :error-messages="
                    formErrors && formErrors.product ? formErrors.product : ''
                  "
                  @input="
                    formErrors && formErrors.product
                      ? delete formErrors.product
                      : ''
                  "
                />
              </v-col>
              <v-col cols="2" class="pa-1">
                <v-text-field
                  class="rounded-lg"
                  outlined
                  hide-details="auto"
                  dense
                  label="Service*"
                  v-model="orderForm.service"
                  :rules="[(val) => !!val || 'Service is required']"
                  :error-messages="
                    formErrors && formErrors.service ? formErrors.service : ''
                  "
                  @input="
                    formErrors && formErrors.service
                      ? delete formErrors.service
                      : ''
                  "
                />
              </v-col>
              <v-col cols="2" class="pa-1">
                <v-text-field
                  outlined
                  class="rounded-lg"
                  hide-details="auto"
                  dense
                  label="Ready At Time*"
                  v-model="orderForm.ready_at_time"
                  :rules="[(val) => !!val || 'Ready At Time is required']"
                  :error-messages="
                    formErrors && formErrors.ready_at_time
                      ? formErrors.ready_at_time
                      : ''
                  "
                  @input="
                    formErrors && formErrors.ready_at_time
                      ? delete formErrors.ready_at_time
                      : ''
                  "
                />
              </v-col>
              <v-col cols="2" class="pa-1">
                <v-text-field
                  outlined
                  class="rounded-lg"
                  hide-details="auto"
                  dense
                  label="Closing Time*"
                  v-model="orderForm.closing_time"
                  :rules="[(val) => !!val || 'Closing Time is required']"
                  :error-messages="
                    formErrors && formErrors.closing_time
                      ? formErrors.closing_time
                      : ''
                  "
                  @input="
                    formErrors && formErrors.closing_time
                      ? delete formErrors.closing_time
                      : ''
                  "
                />
              </v-col>
              <v-col cols="2" class="pa-1">
                <v-text-field
                  outlined
                  class="rounded-lg"
                  hide-details="auto"
                  dense
                  label="Cod Value"
                  :error-messages="
                    formErrors && formErrors.cod_value
                      ? formErrors.cod_value
                      : ''
                  "
                  v-model="orderForm.cod_value"
                  @input="
                    formErrors && formErrors.cod_value
                      ? delete formErrors.cod_value
                      : ''
                  "
                />
              </v-col>
              <v-col cols="2" class="pa-1">
                <v-text-field
                  outlined
                  class="rounded-lg"
                  hide-details="auto"
                  dense
                  label="Consignment Value"
                  :error-messages="
                    formErrors && formErrors.consignment_value
                      ? formErrors.consignment_value
                      : ''
                  "
                  v-model="orderForm.consignment_value"
                  @input="
                    formErrors && formErrors.consignment_value
                      ? delete formErrors.consignment_value
                      : ''
                  "
                />
              </v-col>
              <v-col cols="2" class="pa-1">
                <v-text-field
                  outlined
                  class="rounded-lg"
                  hide-details="auto"
                  dense
                  label="Insurance Value"
                  :error-messages="
                    formErrors && formErrors.insurance_value
                      ? formErrors.insurance_value
                      : ''
                  "
                  v-model="orderForm.insurance_value"
                  @input="
                    formErrors && formErrors.insurance_value
                      ? delete formErrors.insurance_value
                      : ''
                  "
                />
              </v-col>
              <v-col cols="2" class="pa-1">
                <v-text-field
                  outlined
                  class="rounded-lg"
                  hide-details="auto"
                  dense
                  label="Incoterm"
                  :error-messages="
                    formErrors && formErrors.incoterm ? formErrors.incoterm : ''
                  "
                  v-model="orderForm.incoterm"
                  @input="
                    formErrors && formErrors.incoterm
                      ? delete formErrors.incoterm
                      : ''
                  "
                />
              </v-col>
              <v-col cols="2" class="pa-1">
                <BaseSelect
                  class="rounded-lg"
                  :required="true"
                  :is-search-required="false"
                  label="Shipment Process"
                  :isClearable="false"
                  :itemsList="['DTD', 'DTP', 'PTD', 'PTP']"
                  v-model="orderForm.shipment_process"
                  :error-messages="
                    formErrors && formErrors.shipment_process
                      ? formErrors.shipment_process
                      : ''
                  "
                  @change="
                    formErrors && formErrors.shipment_process
                      ? delete formErrors.shipment_process
                      : ''
                  "
                />
              </v-col>

              <v-col cols="2" class="ml-1">
                <v-switch
                  v-model="orderForm.is_multipiece_shipment"
                  label="Is Multi Piece Shipment"
                  :true-value="true"
                  :false-value="false"
                >
                </v-switch>
              </v-col>
              <v-col cols="2">
                <v-switch
                  v-model="orderForm.is_reverse_shipment"
                  label="Is Reverse Shipment"
                  :true-value="true"
                  :false-value="false"
                >
                </v-switch>
              </v-col>
              <v-col cols="2">
                <v-switch
                  v-model="orderForm.is_dangerous_goods"
                  label="Is Dangerous Goods"
                  :true-value="true"
                  :false-value="false"
                >
                </v-switch>
              </v-col>
              <v-col cols="2">
                <v-switch
                  v-model="orderForm.is_stackable"
                  label="Is Stackable"
                  :true-value="true"
                  :false-value="false"
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row>
          <v-col cols="6">
            <v-card
              style="background-color: #f5f8fa"
              elevation="0"
              class="mt-5"
            >
              <v-card-title> Shipper Information </v-card-title>
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      outlined
                      class="rounded-lg"
                      hide-details="auto"
                      dense
                      label="Company Name*"
                      :rules="[(val) => !!val || 'Company Name is required']"
                      v-model="orderForm.shipper_information.company_name"
                      :error-messages="
                        formErrors &&
                        formErrors.shipper_information.company_name
                          ? formErrors.shipper_information.company_name
                          : ''
                      "
                      @input="
                        formErrors &&
                        formErrors.shipper_information.company_name
                          ? delete formErrors.shipper_information.company_name
                          : ''
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      class="rounded-lg"
                      outlined
                      hide-details="auto"
                      dense
                      label="Country*"
                      v-model="orderForm.shipper_information.country"
                      :rules="[(val) => !!val || 'Country is required']"
                      :error-messages="
                        formErrors && formErrors.shipper_information.country
                          ? formErrors.shipper_information.country
                          : ''
                      "
                      @input="
                        formErrors && formErrors.shipper_information.country
                          ? delete formErrors.shipper_information.country
                          : ''
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      class="rounded-lg"
                      outlined
                      hide-details="auto"
                      dense
                      label="Address*"
                      v-model="orderForm.shipper_information.address"
                      :rules="[(val) => !!val || 'Address is required']"
                      :error-messages="
                        formErrors && formErrors.shipper_information.address
                          ? formErrors.shipper_information.address
                          : ''
                      "
                      @input="
                        formErrors && formErrors.shipper_information.address
                          ? delete formErrors.shipper_information.address
                          : ''
                      "
                    />
                  </v-col>

                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      outlined
                      class="rounded-lg"
                      hide-details="auto"
                      dense
                      label="Landmark*"
                      v-model="orderForm.shipper_information.landmark"
                      :rules="[(val) => !!val || 'Landmark is required']"
                      :error-messages="
                        formErrors && formErrors.shipper_information.landmark
                          ? formErrors.shipper_information.landmark
                          : ''
                      "
                      @input="
                        formErrors && formErrors.shipper_information.landmark
                          ? delete formErrors.shipper_information.landmark
                          : ''
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      class="rounded-lg"
                      outlined
                      hide-details="auto"
                      dense
                      label="Phone Code"
                      :error-messages="
                        formErrors && formErrors.shipper_information.phone_code
                          ? formErrors.shipper_information.phone_code
                          : ''
                      "
                      v-model="orderForm.shipper_information.phone_code"
                      @input="
                        formErrors && formErrors.shipper_information.phone_code
                          ? delete formErrors.shipper_information.phone_code
                          : ''
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      outlined
                      class="rounded-lg"
                      hide-details="auto"
                      dense
                      label="Post Code"
                      :error-messages="
                        formErrors && formErrors.shipper_information.postcode
                          ? formErrors.shipper_information.postcode
                          : ''
                      "
                      v-model="orderForm.shipper_information.postcode"
                      @input="
                        formErrors && formErrors.shipper_information.postcode
                          ? delete formErrors.shipper_information.postcode
                          : ''
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      outlined
                      class="rounded-lg"
                      hide-details="auto"
                      dense
                      label="Primary Contact Number*"
                      v-model="
                        orderForm.shipper_information.primary_contact_number
                      "
                      :rules="[
                        (val) => !!val || 'Primary Contact Number is required',
                      ]"
                      :error-messages="
                        formErrors &&
                        formErrors.shipper_information.primary_contact_number
                          ? formErrors.shipper_information
                              .primary_contact_number
                          : ''
                      "
                      @input="
                        formErrors &&
                        formErrors.shipper_information.primary_contact_number
                          ? delete formErrors.shipper_information
                              .primary_contact_number
                          : ''
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      outlined
                      class="rounded-lg"
                      hide-details="auto"
                      dense
                      label="Primary Contact Person*"
                      v-model="
                        orderForm.shipper_information.primary_contact_person
                      "
                      :rules="[
                        (val) => !!val || 'Primary Contact Person is required',
                      ]"
                      :error-messages="
                        formErrors &&
                        formErrors.shipper_information.primary_contact_person
                          ? formErrors.shipper_information
                              .primary_contact_person
                          : ''
                      "
                      @input="
                        formErrors &&
                        formErrors.shipper_information.primary_contact_person
                          ? delete formErrors.shipper_information
                              .primary_contact_person
                          : ''
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      class="rounded-lg"
                      outlined
                      hide-details="auto"
                      dense
                      label="Secondary Contact Person"
                      :error-messages="
                        formErrors &&
                        formErrors.shipper_information.secondary_contact_person
                          ? formErrors.shipper_information
                              .secondary_contact_person
                          : ''
                      "
                      v-model="
                        orderForm.shipper_information.secondary_contact_person
                      "
                      @input="
                        formErrors &&
                        formErrors.shipper_information.secondary_contact_person
                          ? delete formErrors.shipper_information
                              .secondary_contact_person
                          : ''
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      class="rounded-lg"
                      outlined
                      hide-details="auto"
                      dense
                      label="Secondary Contact Number"
                      :error-messages="
                        formErrors &&
                        formErrors.shipper_information.secondary_contact_number
                          ? formErrors.shipper_information
                              .secondary_contact_number
                          : ''
                      "
                      v-model="
                        orderForm.shipper_information.secondary_contact_number
                      "
                      @input="
                        formErrors &&
                        formErrors.shipper_information.secondary_contact_number
                          ? delete formErrors.shipper_information
                              .secondary_contact_number
                          : ''
                      "
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card
              style="background-color: #f5f8fa"
              elevation="0"
              class="mt-5"
            >
              <v-card-title> Consignee Information </v-card-title>
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      outlined
                      class="rounded-lg"
                      hide-details="auto"
                      dense
                      label="Company Name*"
                      v-model="orderForm.consignee_information.company_name"
                      :rules="[(val) => !!val || 'Company Name is required']"
                      :error-messages="
                        formErrors &&
                        formErrors.consignee_information.company_name
                          ? formErrors.consignee_information.company_name
                          : ''
                      "
                      @input="
                        formErrors &&
                        formErrors.consignee_information.company_name
                          ? delete formErrors.consignee_information.company_name
                          : ''
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      outlined
                      class="rounded-lg"
                      hide-details="auto"
                      dense
                      label="Country*"
                      v-model="orderForm.consignee_information.country"
                      :rules="[(val) => !!val || 'Country is required']"
                      :error-messages="
                        formErrors && formErrors.consignee_information.country
                          ? formErrors.consignee_information.country
                          : ''
                      "
                      @input="
                        formErrors && formErrors.consignee_information.country
                          ? delete formErrors.consignee_information.country
                          : ''
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      outlined
                      class="rounded-lg"
                      hide-details="auto"
                      dense
                      label="Address*"
                      v-model="orderForm.consignee_information.address"
                      :rules="[(val) => !!val || 'Address is required']"
                      :error-messages="
                        formErrors && formErrors.consignee_information.address
                          ? formErrors.consignee_information.address
                          : ''
                      "
                      @input="
                        formErrors && formErrors.consignee_information.address
                          ? delete formErrors.consignee_information.address
                          : ''
                      "
                    />
                  </v-col>

                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      outlined
                      class="rounded-lg"
                      hide-details="auto"
                      dense
                      label="Landmark*"
                      v-model="orderForm.consignee_information.landmark"
                      :rules="[(val) => !!val || 'Landmark is required']"
                      :error-messages="
                        formErrors && formErrors.consignee_information.landmark
                          ? formErrors.consignee_information.landmark
                          : ''
                      "
                      @input="
                        formErrors && formErrors.consignee_information.landmark
                          ? delete formErrors.consignee_information.landmark
                          : ''
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      outlined
                      class="rounded-lg"
                      hide-details="auto"
                      dense
                      label="Phone Code"
                      :error-messages="
                        formErrors &&
                        formErrors.consignee_information.phone_code
                          ? formErrors.consignee_information.phone_code
                          : ''
                      "
                      v-model="orderForm.consignee_information.phone_code"
                      @input="
                        formErrors &&
                        formErrors.consignee_information.phone_code
                          ? delete formErrors.consignee_information.phone_code
                          : ''
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      class="rounded-lg"
                      outlined
                      hide-details="auto"
                      dense
                      label="Post Code"
                      :error-messages="
                        formErrors && formErrors.consignee_information.postcode
                          ? formErrors.consignee_information.postcode
                          : ''
                      "
                      v-model="orderForm.consignee_information.postcode"
                      @input="
                        formErrors && formErrors.consignee_information.postcode
                          ? delete formErrors.consignee_information.postcode
                          : ''
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      class="rounded-lg"
                      outlined
                      hide-details="auto"
                      dense
                      label="Primary Contact Number*"
                      v-model="
                        orderForm.consignee_information.primary_contact_number
                      "
                      :rules="[
                        (val) => !!val || 'Primary Contact Number is required',
                      ]"
                      :error-messages="
                        formErrors &&
                        formErrors.consignee_information.primary_contact_number
                          ? formErrors.consignee_information
                              .primary_contact_number
                          : ''
                      "
                      @input="
                        formErrors &&
                        formErrors.consignee_information.primary_contact_number
                          ? delete formErrors.consignee_information
                              .primary_contact_number
                          : ''
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      outlined
                      class="rounded-lg"
                      hide-details="auto"
                      dense
                      label="Primary Contact Person*"
                      v-model="
                        orderForm.consignee_information.primary_contact_person
                      "
                      :rules="[
                        (val) => !!val || 'Primary Contact Person is required',
                      ]"
                      :error-messages="
                        formErrors &&
                        formErrors.consignee_information.primary_contact_person
                          ? formErrors.consignee_information
                              .primary_contact_person
                          : ''
                      "
                      @input="
                        formErrors &&
                        formErrors.consignee_information.primary_contact_person
                          ? delete formErrors.consignee_information
                              .primary_contact_person
                          : ''
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      class="rounded-lg"
                      outlined
                      hide-details="auto"
                      dense
                      label="Secondary Contact Person"
                      :error-messages="
                        formErrors &&
                        formErrors.consignee_information
                          .secondary_contact_person
                          ? formErrors.consignee_information
                              .secondary_contact_person
                          : ''
                      "
                      v-model="
                        orderForm.consignee_information.secondary_contact_person
                      "
                      @input="
                        formErrors &&
                        formErrors.consignee_information
                          .secondary_contact_person
                          ? delete formErrors.consignee_information
                              .secondary_contact_person
                          : ''
                      "
                    />
                  </v-col>
                  <v-col cols="6" class="pa-1">
                    <v-text-field
                      outlined
                      class="rounded-lg"
                      hide-details="auto"
                      dense
                      label="Secondary Contact Number"
                      :error-messages="
                        formErrors &&
                        formErrors.consignee_information
                          .secondary_contact_number
                          ? formErrors.consignee_information
                              .secondary_contact_number
                          : ''
                      "
                      v-model="
                        orderForm.consignee_information.secondary_contact_number
                      "
                      @input="
                        formErrors &&
                        formErrors.consignee_information
                          .secondary_contact_number
                          ? delete formErrors.consignee_information
                              .secondary_contact_number
                          : ''
                      "
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-card style="background-color: #f5f8fa" elevation="0" class="mt-5">
          <v-card-title> Package Information </v-card-title>
          <v-card-text>
            <v-row
              no-gutters
              v-for="(item, i) in orderForm.order_packages"
              :key="i"
            >
              <v-col cols="1" class="pa-1">
                <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  label="Length"
                  v-model="item.length"
                />
              </v-col>
              <v-col cols="2" class="pa-1">
                <v-text-field
                  outlined
                  class="rounded-lg"
                  hide-details="auto"
                  dense
                  label="Width"
                  v-model="item.width"
                />
              </v-col>
              <v-col cols="1" class="pa-1">
                <v-text-field
                  outlined
                  class="rounded-lg"
                  hide-details="auto"
                  dense
                  label="Height"
                  v-model="item.height"
                />
              </v-col>
              <v-col cols="2" class="pa-1">
                <v-text-field
                  outlined
                  class="rounded-lg"
                  hide-details="auto"
                  dense
                  label="Weight*"
                  v-model="item.weight"
                  :rules="[(val) => !!val || 'Weight is required']"
                  :error-messages="
                    formErrors && formErrors.weight ? formErrors.weight : ''
                  "
                  @input="
                    formErrors && formErrors.weight
                      ? delete formErrors.weight
                      : ''
                  "
                />
              </v-col>

              <!-- <v-col cols="1" class="pa-1">
                <v-text-field
                  outlined
                  class="rounded-lg"
                  hide-details="auto"
                  dense
                  label="Pieces"
                  v-model="item.pieces"
                />
              </v-col> -->
              <v-col cols="2" class="pa-1">
                <v-text-field
                  outlined
                  class="rounded-lg"
                  hide-details="auto"
                  dense
                  label="Content*"
                  v-model="item.content"
                  :rules="[(val) => !!val || 'Content is required']"
                  :error-messages="
                    formErrors && formErrors.content ? formErrors.content : ''
                  "
                  @input="
                    formErrors && formErrors.content
                      ? delete formErrors.content
                      : ''
                  "
                />
              </v-col>
              <v-col cols="2" class="pa-1">
                <v-text-field
                  outlined
                  class="rounded-lg"
                  hide-details="auto"
                  dense
                  label="Barcode Value"
                  v-model="item.barcode_value"
                />
              </v-col>
              <v-col cols="1" class="pa-1 d-flex align-center">
                <v-btn color="green" class="mr-2" small @click="addItem()">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn
                  color="red"
                  small
                  :disabled="!isValid"
                  @click="removeItem(i)"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        class="rounded-lg"
        :disabled="!isValid"
        small
        @click="saveData()"
      >
        Save
      </v-btn>
      <v-btn
        color="primary"
        :disabled="!isValid"
        class="rounded-lg"
        small
        @click="submitForm()"
      >
        Save & Send
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { bus } from "@/main";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";

export default {
  components: { BaseDialog, BaseSelect },
  props: {
    value: {
      type: Boolean,
    },
    orderObj: {
      Object,
    },
    integrationPartner: {
      type: String,
    },
    objId: {
      type: Number,
    },
  },
  data() {
    return {
      formErrors: {
        shipper_information: {},
        consignee_information: {},
        non_field_errors: [],
        order_packages: [],
      },
      isValid: true,
      orderForm: {
        shipper_information: {},
        consignee_information: {},
        order_packages: [{ pieces: 1 }],
      },
    };
  },

  computed: {
    showEditForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    showEditForm(val) {
      if (val) {
        this.orderForm = { ...this.orderObj };
        this.orderForm.shipper_information = {
          ...this.orderObj.shipper_information,
        };
        this.orderForm.consignee_information = {
          ...this.orderObj.consignee_information,
        };
      } else {
        this.resetForm();
      }
    },
  },
  methods: {
    saveData() {
      let data = {
        ...this.orderForm,
      };
      this.$emit("save", data);
      this.showEditForm = false;
    },
    submitForm() {
      bus.$emit("showLoader", true);
      let data;

      if (this.integrationPartner == "Fero") {
        data = {
          ...this.orderForm,
        };
      } else if (this.integrationPartner == "Carriyo") {
        data = {
          ...this.orderForm,
        };
      }
      this.$api.inbound
        .singleResendShipmentFailedApis(this.objId, data)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: res.data.message,
            color: "success",
          });
          this.resetForm();
          this.showEditForm = false;
          this.$emit("refreshList");
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          this.formErrors = err.data;
          bus.$emit("showToastMessage", {
            message: err.data.message,
            color: "red",
          });
        });
    },
    resetForm() {
      // const form = this.$refs.orderForm;
      // if (form) {
      //   form.reset();
      // }
      this.$nextTick(() => {
        const form = this.$refs.orderForm;
        if (form) {
          form.resetValidation();
        }
      });
      this.orderForm = {
        shipper_information: {},
        consignee_information: {},
        order_packages: [],
      };
      this.formErrors = {
        shipper_information: {},
        consignee_information: {},
        non_field_errors: [],
        order_packages: [],
      };
    },
    addItem() {
      this.orderForm.order_packages.push({});
    },
    removeItem(index) {
      this.orderForm.order_packages.splice(index, 1);
    },
  },
};
</script>