<template>
  <div>
    <v-card style="background-color: #f5f8fa" elevation="0">
      <v-card-text class="pa-1">
        <v-icon @click="$router.back()">mdi-chevron-left</v-icon>
        <span class="pl-2 text--primary"> Go Back to Shipments </span>
        <v-spacer></v-spacer>
      </v-card-text>
    </v-card>
    <v-card elevation="0">
      <v-card-title class="ma-0 pb-0">
        <span class="pl-2 font-weight-bold text--primary">
          <span>Reference Number - {{ shipmentObject.reference_number }}</span>
          <v-icon
            class="ml-2"
            :color="shipmentObject.is_success == 'Yes' ? 'green' : 'red'"
          >
            {{
              shipmentObject.is_success == "Yes"
                ? "mdi-checkbox-marked-circle"
                : "mdi-close-circle"
            }}
          </v-icon>
          <br />
          <span v-if="shipmentObject.awb_number">
            AWB Number - {{ shipmentObject.awb_number }}
          </span>
        </span>
        <v-spacer></v-spacer>

        <v-tooltip bottom v-if="shipmentObject.is_success === 'Yes'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              color="primary"
              small
              depressed
              v-bind="attrs"
              v-on="on"
              @click="fetchShipmentStatus()"
            >
              <v-icon small>mdi-reload</v-icon>
            </v-btn>
          </template>
          <span>Fetch Status</span>
        </v-tooltip>

        <v-menu offset-y v-if="shipmentObject.is_success == 'No'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              <v-icon> mdi-dots-horizontal </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item value="Edit & Book" @click="showEditForm = true">
              <v-icon small class="mr-2"> mdi-eye </v-icon>
              <v-list-item-title>Edit & Book</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <span class="pl-2 text--primary">
          Created At -
          {{
            shipmentObject && shipmentObject.created
              ? convertEventTimeStamp(shipmentObject.created) +
                " (" +
                formatLastUpdatedTime(shipmentObject.created) +
                ")"
              : null
          }}
        </span>
        <v-tabs fixed-tabs align-tabs="start" v-model="currentTab">
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab
            v-for="(item, i) in items"
            :key="i"
            style="height: 60%"
            class="mr-2 grey rounded-pill white--text"
          >
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </v-card-text>
    </v-card>
    <v-card v-if="shipmentObject.is_success == 'No'" elevation="0" class="mt-3">
      <v-card-text
        v-if="
          shipmentObject &&
          shipmentObject.response &&
          shipmentObject.response.error
        "
        class="pa-0"
      >
        <v-list
          dense
          class="ml-2 pa-0"
          style="background: inherit !important"
          v-for="(error, key, i) in shipmentObject.response.error"
          :key="i"
        >
          <v-list-item dense>
            <span class="red--text font-weight-bold"
              >{{ key }} -{{ error }}</span
            >
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-card style="background-color: #f5f8fa" elevation="0">
      <v-card-text>
        <v-tabs-items v-model="currentTab">
          <v-tab-item>
            <FeroPartnerSummary
              v-if="shipmentObject.integration_partner_name == 'Fero'"
              :shipmentObject="shipmentObject"
              :payloadData="shipmentObject.payload"
            />
            <CarriyoPartnerSummary
              v-if="shipmentObject.integration_partner_name == 'Carriyo'"
              :shipmentObject="shipmentObject"
              :payloadData="shipmentObject.payload"
            />
          </v-tab-item>
          <v-tab-item>
            <v-card elevation="0">
              <v-card-title>History</v-card-title>
            </v-card>
            <div v-if="!historyLogs.length" class="py-3 d-flex justify-center">
              No History Found
            </div>
            <v-timeline v-else dense>
              <v-timeline-item
                color="secondary"
                v-for="item in historyLogs"
                :key="item.id"
              >
                <v-alert class="py-2 mb-1" color="grey lighten-2" :value="true">
                  <v-row>
                    <v-col cols="10">
                      {{ item.message }}
                    </v-col>
                    <v-col cols="2" class="d-flex">
                      {{
                        item.created
                          ? convertEventTimeStamp(item.created)
                          : null
                      }}
                    </v-col>
                  </v-row>
                </v-alert>
              </v-timeline-item>
            </v-timeline>
          </v-tab-item>
          <v-tab-item>
            <BaseListLayout
              name="systemList"
              ref="systemList"
              title="System Logs"
              :table-header="systemColumns"
              :table-data="systemLogs"
              :hasAddButton="true"
              :context="context"
              :gridStyle="gridStyle"
              :showColumnSelection="false"
              :total="totalItems"
              @getList="getClientShipmentSystemLogs"
          /></v-tab-item>
          <v-tab-item>
            <v-card elevation="0">
              <v-card-title>AWB Logs</v-card-title>
            </v-card>
            <v-timeline
              v-if="
                shipmentObject &&
                shipmentObject.awb_logs &&
                shipmentObject.awb_logs.length
              "
              dense
            >
              <v-timeline-item
                v-for="(log, i) in shipmentObject.awb_logs"
                :key="i"
                small
              >
                <div>
                  <v-row no-gutters>
                    <v-col cols="6">
                      <span style="font-size: 10px" class="text-caption">
                        {{ log.status }} (
                        {{
                          log.created
                            ? convertEventTimeStamp(log.created)
                            : null
                        }})
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <h5 class="black--text text-caption">
                        {{ log.message }}
                      </h5>
                    </v-col>
                    <v-col cols="12">
                      <span style="font-size: 10px" class="text-caption">
                        Customer Name - {{ log.customer_name }}, Current Branch
                        -
                        {{ log.current_branch }}
                      </span>
                    </v-col>
                  </v-row>
                </div>
              </v-timeline-item>
            </v-timeline>
            <div v-else class="py-3 d-flex justify-center">No Logs Found</div>
          </v-tab-item>

          <!-- Webhook Logs -->
          <v-tab-item>
            <BaseListLayout
              name="webhookLogsList"
              ref="webhookLogsList"
              title="Client Webhook Logs"
              :table-header="clientWebhookLogColumns"
              :table-data="clientWebhookLogsData"
              :context="context"
              :total="totalItems"
              :gridStyle="gridStyle"
              :showColumnSelection="false"
              @getList="getClientWebhookLogsList"
          /></v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <BaseDialog
      v-model="showPayloadPreview"
      width="60vh"
      title="Payload"
      @closeDialog="showPayloadPreview = false"
    >
      <template #dialogContent>
        <div id="pdf-content">
          <pre>{{ payloadPreviewData }}</pre>
        </div>
      </template>
      <template #actions>
        <v-btn small text>
          <v-icon color="primary" @click="copyPayload">
            mdi-content-copy
          </v-icon>
        </v-btn>
      </template>
    </BaseDialog>
    <EditOrderForm
      @refreshList="getClientShipmentHistoryLogs"
      :integrationPartner="shipmentObject.integration_partner_name"
      v-model="showEditForm"
      :orderObj="shipmentObject.payload"
      :objId="shipmentObject.id"
      @save="saveOrderFormData"
    />
  </div>
</template>
<script>
import { bus } from "@/main";
import { formatLastUpdated, convertDateTime } from "@/utils/functions";
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ShipmentCellRenderer from "@/components/AgGridButtons/ShipmentCellRenderer.vue";
import ActionButton from "@/components/AgGridButtons/ActionButton.vue";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import EditOrderForm from "./EditOrderForm.vue";
import FeroPartnerSummary from "./FeroPartnerSummary.vue";
import CarriyoPartnerSummary from "./CarriyoPartnerSummary.vue";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";

export default {
  name: "Shipments",
  components: {
    BaseListLayout,
    ShipmentCellRenderer,
    ActionButton,
    BaseDialog,
    EditOrderForm,
    CarriyoPartnerSummary,
    FeroPartnerSummary,
    CellRenderer,
  },
  data() {
    return {
      fromInboundShipmentDetails: true,
      totalItems: 0,
      showEditForm: false,
      gridStyle: {
        width: "100%",
        height: "60vh",
      },
      currentTab: null,
      shipmentObject: {},
      showPayloadPreview: false,
      payloadPreviewData: {},

      items: [
        { name: "Summary" },
        { name: "History" },
        { name: "System Logs" },
        { name: "Awb Logs" },
        { name: "Webhook Logs" },
      ],
      systemLogs: [],
      historyLogs: [],
      systemColumns: [
        { headerName: "Awb Number", field: "awb", maxWidth: 300 },
        { headerName: "Added By", field: "added_by", maxWidth: 200 },
        {
          headerName: "Is Success",
          field: "is_success",
          cellRenderer: "ShipmentCellRenderer",
          maxWidth: 150,
        },
        { headerName: "Message", field: "message" },
        { headerName: "Status Code", field: "status_code", maxWidth: 150 },
        {
          headerName: "More Details",
          field: "actions",
          cellRenderer: "ActionButton",
          maxWidth: 150,
        },
      ],

      clientWebhookLogsData: [],
      clientWebhookLogColumns: [
        { headerName: "Trace ID", field: "trace_id" },
        { headerName: "AWB Number", field: "awb_number" },
        { headerName: "Organization", field: "organization_name" },
        { headerName: "Client", field: "client_name" },
        { headerName: "Method", field: "method" },
        { headerName: "Status", field: "status" },
        // { headerName: "Endpoint", field: "endpoint" },
        { headerName: "Response Code", field: "status_code" },
        {
          headerName: "Success",
          field: "is_success",
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "Created",
          field: "created",
          cellRenderer: "CellRenderer",
        },
      ],
    };
  },
  watch: {
    currentTab(val) {
      let name = this.items[val].name;
      if (name == "Summary") {
        this.viewLogDetails();
      } else if (name == "History") {
        this.getClientShipmentHistoryLogs();
      } else if (name == "System Logs") {
        // this.getClientShipmentSystemLogs();
      }
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    convertEventTimeStamp(value) {
      return convertDateTime(value);
    },
    formatLastUpdatedTime(value) {
      return formatLastUpdated(value);
    },
    viewLogDetails() {
      let id = this.$route.params.id;
      this.$api.inbound
        .viewLogDetails(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.shipmentObject = res.data;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "error getting data",
            color: "red",
          });
        });
    },
    getClientShipmentSystemLogs(params = {}) {
      if (this.shipmentObject.awb) params.awb = this.shipmentObject.awb;
      else params.request_log = this.$route.params.id;

      this.$api.inbound
        .getClientShipmentSystemLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.systemLogs = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Error getting system logs",
            color: "red",
          });
        });
    },
    getClientWebhookLogsList(params = {}) {
      bus.$emit("showLoader", true);
      if (this.shipmentObject.awb_number)
        params.awb_number = this.shipmentObject.awb_number;
      else params.request_log = this.$route.params.id;

      this.$api.outboundLogs
        .getClientWebhookLogsList({ ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.clientWebhookLogsData = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    getClientShipmentHistoryLogs(params = {}) {
      if (this.shipmentObject.awb) params.awb = this.shipmentObject.awb;
      else params.request_log = this.$route.params.id;

      this.$api.inbound
        .getClientShipmentHistoryLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.historyLogs = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Error getting history logs",
            color: "red",
          });
        });
    },
    fetchShipmentStatus() {
      bus.$emit("showLoader", true);

      this.$api.inbound
        .fetchShipmentStatus(this.shipmentObject.id)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Status Fetched Successfully",
            color: "success",
          });
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    moreDetails(payload) {
      this.payloadPreviewData = payload;
      this.showPayloadPreview = true;
    },
    copyPayload() {
      if (this.payloadPreviewData) {
        navigator.clipboard.writeText(JSON.stringify(this.payloadPreviewData));
        bus.$emit("showToastMessage", {
          message: "Payload Copied Successfully..",
          color: "green",
        });
      }
    },
    saveOrderFormData(data) {
      this.shipmentObject.payload = { ...data };
    },
  },
};
</script>
<style scoped>
.v-tab--active {
  background-color: #17b7d0 !important;
}
</style>