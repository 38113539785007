import { vendorShipments } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getVendorShipmentList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(vendorShipments.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  viewVendorShipment(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${vendorShipments.view_details}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getVendorShipmentSystemLogs(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${vendorShipments.system_logs}`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getVendorShipmentHistoryLogs(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${vendorShipments.history_logs}`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  // Method For Fetching Vendor Shipment Status
  fetchVendorShipmentStatus(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${vendorShipments.fetchStatus}`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
