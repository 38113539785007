import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialog',{attrs:{"title":"Organization Details","fullscreen":""},on:{"closeDialog":function($event){_vm.showOrganizationDetails = false}},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c(VCard,{staticClass:"ma-0 pa-0",staticStyle:{"background-color":"#f5f8fa"},attrs:{"elevation":"0"}},[_c(VCardTitle,[_c(VRow,[_c(VCol,{attrs:{"cols":"2"}},[_vm._v("Incoming Request Log")]),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"10"}},_vm._l((_vm.basicDetails),function(detail){return _c('span',{key:detail.label,staticClass:"mr-8",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(detail.label)+" - "),_c('span',{staticClass:"secondary--text"},[_vm._v(_vm._s(detail.value)+" ")])])}),0)],1)],1),_c(VCardText,[_c('BaseListLayout',{ref:"organizationOrderLogsList",attrs:{"table-header":_vm.orderColumns,"table-data":_vm.organizationOrderLogsList,"context":_vm.context,"total":_vm.totalOrders,"name":"organizationOrderLogsList","gridStyle":_vm.gridStyle,"showColumnSelection":false},on:{"getList":_vm.getOrganizationOrderLogsList}})],1)],1)]},proxy:true}]),model:{value:(_vm.showOrganizationDetails),callback:function ($$v) {_vm.showOrganizationDetails=$$v},expression:"showOrganizationDetails"}})
}
var staticRenderFns = []

export { render, staticRenderFns }