import { router } from "@/router/index.js";
import axios from "axios";

function authHeader() {
  let user = localStorage.getItem("user");
  if (user) {
    return JSON.parse(user).access;
  } else {
    return "";
  }
}

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  let head = authHeader();
  if (head) {
    config.headers.Authorization = `Bearer ${head}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (config) => {
    return config;
  },
  async (err) => {
    const originalRequest = err.config;
    if (err.response.status == 401) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.messages &&
        err.response.data.messages[0] &&
        err.response.data.messages[0].message == "Token is invalid or expired"
      ) {
        try {
          const userData = localStorage.getItem("user");
          const refresh = JSON.parse(userData).refresh;

          const response = await axios.post(
            `${originalRequest.baseURL}/api/v1/token/refresh/`,
            {
              refresh: refresh,
            }
          );

          if (response.status == 200) {
            let newUser = {
              refresh: refresh,
              access: response?.data?.access,
            };
            localStorage.setItem("user", JSON.stringify(newUser));
            return await axiosInstance(originalRequest);
          }
        } catch (error) {
          localStorage.clear();
          if (error.response.status == 401)
            router.push("/login").catch((err) => {
              console.log("err", err);
            });
        }
      }
      router.push("/login").catch((err) => {
        console.log("err", err);
      });
    }
    throw err;
  }
);

export default axiosInstance;
