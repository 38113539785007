<template>
  <v-row v-if="filter_for == 'vendor'" no-gutters>
    <v-col cols="3" class="pl-1">
      <v-text-field
        outlined
        class="rounded-pill"
        v-model.trim="filters.order_awb"
        prepend-inner-icon="mdi-magnify"
        dense
        label="Shipment AWB"
        clearable
        hide-details="auto"
        @input="applyFilters"
      />
    </v-col>
    <v-col v-if="!isUserTypeOrganization" cols="2" class="pl-1">
      <BaseSelect
        :isSearchRequired="false"
        class="rounded-pill"
        label="Organization"
        :isClearable="true"
        :itemsList="organizationList"
        item-text="name"
        item-value="id"
        v-model="filters.organization"
        @change="
          applyFilters();
          getClientList();
        "
      />
    </v-col>
    <v-col cols="2" class="pl-1">
      <BaseSelect
        :isSearchRequired="false"
        class="rounded-pill"
        label="Vendor"
        :isClearable="true"
        :itemsList="vendorList"
        item-text="display_value"
        item-value="value"
        v-model="filters.vendor"
        @change="applyFilters"
      />
    </v-col>
  </v-row>
  <v-row v-else no-gutters>
    <v-col class="pl-1" cols="2">
      <BaseDatePickerInput
        v-model="filters.start_date"
        id="start_date"
        name="start_date"
        label="Start Date"
        clearable
        @input="applyFilters"
      />
    </v-col>
    <v-col class="pl-1" cols="2">
      <BaseDatePickerInput
        v-model="filters.end_date"
        id="end_date"
        name="end_date"
        label="End Date"
        clearable
        :min="filters.start_date"
        @input="applyFilters"
      />
    </v-col>
    <v-col class="pl-1" cols="2">
      <BaseSelect
        label="Integration Partner*"
        class="rounded-pill"
        :required="true"
        :isSearchRequired="false"
        :itemsList="integrationPartnerList"
        :isClearable="true"
        item-text="name"
        item-value="id"
        v-model="filters.integration_partner"
        @change="applyFilters"
      />
    </v-col>
    <v-col v-if="!isUserTypeOrganization" cols="2" class="pl-1">
      <BaseSelect
        :isSearchRequired="false"
        class="rounded-pill"
        label="Organization"
        :isClearable="true"
        :itemsList="organizationList"
        item-text="name"
        item-value="id"
        v-model="filters.organization"
        @change="
          applyFilters();
          getClientList();
        "
      />
    </v-col>
    <v-col v-if="filter_for == 'inbound'" cols="2" class="pl-1">
      <BaseSelect
        :isSearchRequired="false"
        class="rounded-pill"
        label="Client"
        :isClearable="true"
        :itemsList="clientList"
        item-text="name"
        item-value="id"
        multiple
        v-model="filters.client"
        @change="applyFilters"
      />
    </v-col>
    <!-- <v-col cols="2" class="pl-1">
      <BaseSelect
        :isSearchRequired="false"
        class="rounded-pill"
        label="Status Code"
        :isClearable="true"
        item-text="label"
        item-value="value"
        :itemsList="$globalConstant.choices.default.statusCodeOption"
        v-model="filters.status_code"
        @change="applyFilters"
      />
    </v-col> -->
    <v-col cols="2" class="pl-2 d-flex align-center">
      <v-switch
        class="ma-0 pr-2"
        v-model="isSuccess"
        label="Success"
        :true-value="true"
        :false-value="false"
        hide-details
        @change="applyFilters"
      />
      <v-switch
        class="ma-0"
        v-model="isFailed"
        label="Failed"
        :true-value="true"
        :false-value="false"
        hide-details
        @change="applyFilters"
      />
    </v-col>
  </v-row>
</template>

<script>
import BaseDatePickerInput from "@/components/BaseComponents/BaseDatePickerInput.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import { getUserProfile } from "@/utils/functions.js";
import { bus } from "@/main";

export default {
  components: { BaseDatePickerInput, BaseSelect },
  props: {
    title: String,
    filter_for: String,
  },
  data() {
    return {
      filters: {},
      organizationList: [],
      clientList: [],
      vendorList: [],
      integrationPartnerList: [],
      isFailed: false,
      isSuccess: false,
    };
  },
  computed: {
    isUserTypeOrganization() {
      if (getUserProfile()) {
        return getUserProfile().user_type == "organization";
      }
    },
  },
  methods: {
    getIntegrationPartnerSelectionList() {
      let params = {
        limit: "all",
      };
      this.$api.integrationPartner
        .getIntegrationPartnerSelectionList(params)
        .then((res) => {
          this.integrationPartnerList = res.data;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: "Error for getting list",
            color: "red",
          });
        });
    },
    getOrganizationOptions() {
      this.$api.organization
        .getOrganizationOptions()
        .then((res) => {
          this.organizationList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getClientList() {
      let org =
        this.filters &&
        this.filters.organization &&
        this.filters.organization.length
          ? this.filters.organization.join(",")
          : null;
      this.$api.client
        .getClientList({ limit: "all", organization: org })
        .then((res) => {
          this.clientList = res?.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getOrganizationVendorList() {
      this.$api.organizationVendor
        .getVendorOptions({ request_type: "form" })
        .then((res) => {
          this.vendorList = res.data.vendor;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: "Error Fetching vendor list",
            color: "red",
          });
        });
    },
    applyFilters() {
      if (
        (this.isSuccess == false && this.isFailed == false) ||
        (this.isSuccess == true && this.isFailed == true)
      ) {
        delete this.filters.is_success;
      } else if (this.isSuccess == true && this.isFailed == false) {
        this.filters.is_success = true;
      } else if (this.isSuccess == false && this.isFailed == true) {
        this.filters.is_success = false;
      }
      this.$emit("applyFilters", this.filters);
    },
  },
  mounted() {
    this.getOrganizationOptions();
    this.getOrganizationVendorList();
    this.getIntegrationPartnerSelectionList();
    if (this.filter_for == "vendor") {
      let f = localStorage.getItem("vendor_shipments_filters");
      if (f) {
        this.filters = JSON.parse(f);
      }
    } else if (this.filter_for == "inbound") {
      let f = localStorage.getItem("client_shipments_filters");
      if (f) {
        this.filters = JSON.parse(f);

        if (this.filters.is_success == true) {
          this.isSuccess = true;
        } else {
          this.isSuccess = false;
        }

        if (this.filters.is_success == false) {
          this.isFailed = true;
        } else {
          this.isFailed = false;
        }
      }
    }
    this.getClientList();
  },
};
</script>
