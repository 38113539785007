import { outboundLogs } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  //Webhook Logs
  getClientWebhookLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(outboundLogs.webhook, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getClientWebhookLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${outboundLogs.webhook}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  editClientWebhookLogsObject(params, id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${outboundLogs.webhook}${id}/resend_request/`, params)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkResendClientWebhookLog(params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${outboundLogs.webhook}resend_webhook_update/`, params)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //Order Logs
  getOrderLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(outboundLogs.order, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrderLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${outboundLogs.order}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkResendOrderLog(params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${outboundLogs.order}resend_bulk_request/`, params)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //Order Cancel
  getCancelOrderLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(outboundLogs.cancelOrder, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkResendCancelOrderLog(params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${outboundLogs.cancelOrder}resend_bulk_request/`, params)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCancelOrderLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${outboundLogs.cancelOrder}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //Track AWB
  getTrackAWBLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(outboundLogs.trackAWB, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTrackAWBLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${outboundLogs.trackAWB}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //Generate AWB PDF Logs
  getGenerateAWBPdfLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(outboundLogs.generateAWBPdf, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getGenerateAWBPdfLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${outboundLogs.generateAWBPdf}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //Order Pricing Logs
  getOrderPricingLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(outboundLogs.orderPricing, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrderPricingLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${outboundLogs.orderPricing}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //Products Logs
  getProductsLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(outboundLogs.products, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getProductsLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${outboundLogs.products}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //Services Logs
  getServicesLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(outboundLogs.services, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getServicesLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${outboundLogs.services}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //Instructions Logs
  getInstructionsLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(outboundLogs.instructions, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getInstructionsLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${outboundLogs.instructions}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //Locations Logs
  getLocationsLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(outboundLogs.locations, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getLocationsLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${outboundLogs.locations}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //Areas Logs
  getAreasLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(outboundLogs.areas, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getAreasLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${outboundLogs.areas}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
