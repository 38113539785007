<template>
  <v-row>
    <!------------------------------------------ Inbound Request Start -------------------------------------------------->
    <v-col v-if="params.column.colId == 'reference_number'" cols="12">
      <span
        v-if="$route.path == '/vendor-shipment/list'"
        class="primary--text"
        @click="viewVendorDetails()"
      >
        {{ params.data.reference_number }}
      </span>
      <span v-else class="primary--text" @click="viewDetails()">
        {{ params.data.reference_number }}
      </span>
    </v-col>

    <v-col cols="12" v-if="params.column.colId == 'created'">
      {{ getDate }} <br />
    </v-col>

    <v-col v-if="params.column.colId == 'is_success'">
      <v-tooltip
        content-class="custom-tooltip"
        color="#E5E5E5"
        bottom
        v-if="
          (params.data.is_success == 'No' || params.data.is_success == false) &&
          params.data.response !== null
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <span
            class="text-white text-body-2 mr-1 font-weight-bold"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="red">mdi-close-circle</v-icon>
          </span>
        </template>
        <v-row>
          <v-col cols="12" class="pa-0">
            <v-list
              dense
              style="background: inherit !important"
              v-for="(error, i) in errors"
              :key="i"
            >
              <v-list-item dense style="min-height: 10px !important">
                <span>{{ error }}</span>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-tooltip>
      <v-icon v-else color="green">mdi-checkbox-marked-circle</v-icon>
    </v-col>

    <v-col v-if="params.column.colId == 'has_updated'">
      <v-icon v-if="params.data.has_updated == 'No'" color="red">
        mdi-close-circle
      </v-icon>
      <v-icon v-else color="green">mdi-checkbox-marked-circle</v-icon>
    </v-col>

    <v-col cols="12" v-if="params.column.colId == 'has_resend'">
      <v-icon v-if="params.data.has_resend == 'No'" color="red">
        mdi-close-circle
      </v-icon>
      <v-icon v-else color="green">mdi-checkbox-marked-circle</v-icon>
    </v-col>

    <!-------------------------------------------- Inbound Request End -------------------------------------------------->
  </v-row>
</template>

<script>
import { convertDateTime, formatLastUpdated } from "@/utils/functions";

export default {
  data() {
    return {
      errors: [],
    };
  },
  computed: {
    // getDate() {
    //   let value = this.params.data.created
    //     ? this.params.data.created
    //     : this.params.data.updated;
    //   const date = new Date(value);
    //   const options = {
    //     year: "numeric",
    //     month: "numeric",
    //     day: "numeric",
    //     hour12: false, // Use 24-hour format
    //   };
    //   const d = new Intl.DateTimeFormat("en-US", options).format(date);
    //   return d;
    // },
    // getTime() {
    //   let value = this.params.data.created
    //     ? this.params.data.created
    //     : this.params.data.updated;
    //   const date = new Date(value);
    //   const options = {
    //     hour: "numeric",
    //     minute: "numeric",
    //     second: "numeric",
    //     hour12: false, // Use 24-hour format
    //   };

    //   const time = new Intl.DateTimeFormat("en-US", options).format(date);
    //   return time;
    // },
    getDate() {
      return convertDateTime(this.params.data.created);
    },
    checkResponse() {
      return this.params.data.is_success == "No" && this.params.data.response;
    },
  },
  methods: {
    viewDetails() {
      this.params.context.parentComponent.viewDetails(this.params.data.id);
    },
    viewVendorDetails() {
      this.params.context.parentComponent.viewVendorDetails(
        this.params.data.id,
        this.params.data.awb
      );
    },
    lastUpdated() {
      formatLastUpdated(this.params.data.updated);
    },
  },
  mounted() {
    if (
      this.checkResponse &&
      this.params.data.response.error &&
      typeof this.params.data.response.error == "object" &&
      Object.keys(this.params.data.response.error).length
    ) {
      this.errors = this.params.data.response.error;
    } else if (this.checkResponse && this.params.data.response.message) {
      this.errors = this.params.data.response;
    } else if (this.checkResponse && this.params.data.status_code == 500) {
      this.errors = ["Connection error"];
    }
  },
};
</script>
