<template>
  <v-row class="ma-0 pa-0 overflow-y-auto" style="height: 600px" no-gutters>
    <v-col cols="9" class="mt-3">
      <v-row no-gutters>
        <v-col cols="4">
          <v-card class="rounded-xl" elevation="0">
            <v-card-title class="ma-0 primary--text">
              Basic Information
            </v-card-title>
            <v-card-text>
              <v-row
                no-gutters
                v-for="detail in basicDetails"
                :key="detail.label"
              >
                <v-col cols="6">
                  {{ detail.label }}
                </v-col>
                <v-col cols="6">
                  <span class="primary--text">
                    {{ detail.value }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="3" class="ml-5">
          <v-card class="rounded-xl" elevation="0">
            <v-card-title class="ma-0 primary--text"> Origin </v-card-title>
            <v-card-text>
              <v-row
                no-gutters
                v-for="origin in originAddress"
                :key="origin.label"
              >
                <v-col cols="4">
                  {{ origin.label }}
                </v-col>
                <v-col cols="8">
                  <span class="primary--text">
                    {{ origin.value }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="4" class="ml-5">
          <v-card class="rounded-xl" elevation="0">
            <v-card-title class="ma-0 primary--text">
              Destination
            </v-card-title>
            <v-card-text>
              <v-row
                no-gutters
                v-for="destination in destinationAddress"
                :key="destination.label"
              >
                <v-col cols="4">
                  {{ destination.label }}
                </v-col>
                <v-col cols="8">
                  <span class="primary--text">
                    {{ destination.value }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters class="mt-3">
        <v-col cols="4">
          <v-card class="rounded-xl" elevation="0">
            <v-card-title class="ma-0 primary--text">
              Additional Information
            </v-card-title>
            <v-card-text>
              <v-row
                no-gutters
                v-for="detail in additionalDetails"
                :key="detail.label"
              >
                <v-col cols="4">
                  {{ detail.label }}
                </v-col>
                <v-col cols="8">
                  <span class="primary--text">
                    {{ detail.value }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="4" class="ml-5">
          <v-card class="rounded-xl" elevation="0">
            <v-card-title class="ma-0 primary--text">
              Parcel Information
            </v-card-title>
            <v-card-text>
              <v-row
                no-gutters
                v-for="parcel in parcelInformation"
                :key="parcel.label"
              >
                <v-col cols="4">
                  {{ parcel.label }}
                </v-col>
                <v-col cols="8">
                  <span class="primary--text">
                    {{ parcel.value }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <!-- Events -->
    <v-col cols="3" class="mt-3 ma-0 pa-0">
      <v-card class="rounded-xl" elevation="0">
        <v-card-title class="ma-0 primary--text"> Events </v-card-title>
        <v-card-text>
          <v-timeline dense side="end" light align-top>
            <v-timeline-item
              v-for="(event, i) in trackingDetailObject.ShipmentStatuses"
              :key="i"
              small
              fill-dot
            >
              <div class="text-black">
                <div class="font-weight-normal">
                  <strong>{{ event.status }}</strong>
                </div>
                <div class="text-caption">
                  <v-icon small>mdi-calendar-clock </v-icon>
                  {{ event.time }}
                </div>
                <div class="text-caption">
                  <v-icon small>mdi-map-marker</v-icon>
                  {{ event.new_location }}
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
  
<script>
import { formatLastUpdated, convertDateTime } from "@/utils/functions";

export default {
  name: "SHLAwbComponent",
  components: {},
  props: {
    vendorDetails: Object,
    trackingDetailObject: Object,
  },
  data() {
    return {};
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    requestData() {
      return this.vendorDetails.request_data;
    },
    basicDetails() {
      return [
        {
          label: "Organization",
          value: this.vendorDetails.organization,
        },
        {
          label: "Vendor AWB Status",
          value: this.vendorDetails.vendor_awb_status,
        },
        {
          label: "Tracking Number",
          value: this.vendorDetails.track_no,
        },
        {
          label: "Reference Number",
          value: this.trackingDetailObject?.Shipment?.reference,
        },
        {
          label: "Main Status",
          value: this.trackingDetailObject?.Shipment?.main_status,
        },
        {
          label: "Last Update Date",
          value: this.trackingDetailObject?.Shipment?.last_update_date
            ? convertDateTime(
                this.trackingDetailObject?.Shipment?.last_update_date
              ) +
              " (" +
              formatLastUpdated(
                this.trackingDetailObject?.Shipment?.last_update_date
              ) +
              ")"
            : null,
        },
        {
          label: "Last Status Sent To Organization",
          value: this.vendorDetails.last_status_sent_to_org
            ? this.vendorDetails.last_status_sent_to_org
            : "N/A",
        },
        {
          label: "Last Update Sent To Organization At",
          value: this.vendorDetails.last_update_sent_to_org_at
            ? convertDateTime(this.vendorDetails.last_update_sent_to_org_at) +
              " (" +
              formatLastUpdated(this.vendorDetails.last_update_sent_to_org_at) +
              ")"
            : null,
        },
        {
          label: "Last Update Received At",
          value: this.vendorDetails.last_update_received_at
            ? convertDateTime(this.vendorDetails.last_update_received_at) +
              " (" +
              formatLastUpdated(this.vendorDetails.last_update_received_at) +
              ")"
            : null,
        },
      ];
    },
    additionalDetails() {
      return [
        {
          label: "SKU",
          value: this.requestData?.sku,
        },
        {
          label: "Pick Date",
          value: this.requestData?.pick_date,
        },
        {
          label: "Service Id",
          value: this.requestData?.service_id,
        },
        {
          label: "Customer Branch Id",
          value: this.requestData?.customer_branch_id,
        },
        {
          label: "Product Type",
          value: this.requestData?.product_type,
        },
        {
          label: "Payment Mode",
          value: this.requestData?.payment_mode,
        },
      ];
    },
    parcelInformation() {
      return [
        {
          label: "Parcel Quantity",
          value: this.requestData?.parcel_quantity,
        },
        {
          label: "Parcel Weight",
          value: this.requestData?.parcel_weight + " KG",
        },
        {
          label: "Weight Total",
          value: this.requestData?.weight_total + " KG",
        },
        {
          label: "Description",
          value: this.requestData?.description,
        },
      ];
    },
    originAddress() {
      return [
        {
          label: "Name",
          value: this.trackingDetailObject?.Shipment?.sender?.name,
        },
        {
          label: "Address",
          value: this.trackingDetailObject?.Shipment?.sender?.address,
        },
        {
          label: "Zipcode",
          value: this.trackingDetailObject?.Shipment?.sender?.zip_code,
        },
        {
          label: "Phone",
          value: this.trackingDetailObject?.Shipment?.sender?.phone,
        },
        {
          label: "Email",
          value: this.trackingDetailObject?.Shipment?.sender?.email,
        },
      ];
    },
    destinationAddress() {
      return [
        {
          label: "Name",
          value: this.trackingDetailObject?.Shipment?.receiver?.name,
        },
        {
          label: "Address",
          value: this.trackingDetailObject?.Shipment?.receiver?.address,
        },
        {
          label: "Zipcode",
          value: this.trackingDetailObject?.Shipment?.receiver?.zip_code,
        },
        {
          label: "Phone",
          value: this.trackingDetailObject?.Shipment?.receiver?.phone,
        },
        {
          label: "Phone2",
          value: this.trackingDetailObject?.Shipment?.receiver?.phone2,
        },
        {
          label: "Email",
          value: this.trackingDetailObject?.Shipment?.receiver?.email,
        },
        {
          label: "Signature",
          value: this.trackingDetailObject?.Shipment?.receiver?.signature,
        },
        {
          label: "Photo",
          value: this.trackingDetailObject?.Shipment?.receiver?.photo,
        },
      ];
    },
  },
};
</script>