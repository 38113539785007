<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        name="OrganizationVendorList"
        ref="organizationVendorList"
        title="Vendor"
        searchable
        :table-header="columns"
        :table-data="organizationVendorList"
        :hasAddButton="true"
        :context="context"
        :total="totalItems"
        :showBackIcon="true"
        localStorageKey="organizationVendorColumns"
        @getList="getOrganizationVendorList"
      >
        <template #addButton>
          <span class="pr-10 text--primary text-sm-body-2">
            Organization : {{ $route.params.name }}
          </span>
          <v-btn
            color="primary"
            small
            depressed
            class="text-capitalize"
            @click="addNewOrganizationVendor"
          >
            Add Vendor
          </v-btn>
        </template>
        <template #dialogs>
          <OrganizationVendorForm
            ref="organizationForm"
            v-model="showOrganizationVendorForm"
            :organizationVendorID="organizationVendorID"
            :editMode="editMode"
            @updateList="updateList"
          />
          <OrganizationVendorBulkStatusMappingDialog
            v-model="showOrganizationVendorBulkStatusMappingDialog"
            :organizationVendorID="organizationVendorID"
            @updateList="updateList"
          />
          <OrganizationVendorStatusInfoDialog
            v-model="showStatusMappingInfoDialog"
            :organizationVendorID="organizationVendorID"
            @updateList="updateList"
          />
        </template>
      </BaseListLayout>
    </v-col>
  </v-row>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ActionsButton from "@/components/AgGridButtons/ActionsButton.vue";
import OrganizationVendorForm from "./OrganizationVendorForm.vue";
import OrganizationVendorBulkStatusMappingDialog from "./OrganizationVendorBulkStatusMappingDialog.vue";
import OrganizationVendorStatusInfoDialog from "./OrganizationVendorStatusInfoDialog.vue";
import { bus } from "@/main";
import { getUserProfile } from "@/utils/functions.js";

export default {
  name: "OrganizationList",
  components: {
    BaseListLayout,
    ActionsButton,
    OrganizationVendorForm,
    OrganizationVendorBulkStatusMappingDialog,
    OrganizationVendorStatusInfoDialog,
  },
  data() {
    return {
      canEdit: true,
      editMode: false,
      organizationVendorList: [],
      showOrganizationVendorForm: false,
      organizationVendorID: null,
      totalItems: 0,
      canMapStatus: true,
      canViewStatusMappingDialog: true,
      showOrganizationVendorBulkStatusMappingDialog: false,
      showStatusMappingInfoDialog: false,
      columns: [
        { headerName: "Vendor Name", field: "vendor" },
        { headerName: "Vendor Code", field: "code" },
        { headerName: "Auth Method", field: "auth_method" },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionsButton",
          minWidth: 100,
        },
      ],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    isUserTypeOrganization() {
      return getUserProfile().user_type == "organization";
    },
  },
  methods: {
    addNewOrganizationVendor() {
      this.editMode = false;
      this.showOrganizationVendorForm = true;
    },
    editDetails(id) {
      this.editMode = true;
      this.organizationVendorID = id;
      this.showOrganizationVendorForm = true;
    },
    bulkUploadStatusSheet(id) {
      this.organizationVendorID = id;
      this.showOrganizationVendorBulkStatusMappingDialog = true;
    },
    viewStatusMappingDialog(id) {
      this.organizationVendorID = id;
      this.showStatusMappingInfoDialog = true;
    },
    getOrganizationVendorList(params) {
      bus.$emit("showLoader", true);
      params = {
        organization: this.$route.params.id,
        ...params,
      };
      let filters = localStorage.getItem("Organization-VendorFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      this.$api.organizationVendor
        .getOrganizationVendorList({ ...filters, ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.organizationVendorList = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    refreshList() {
      this.$refs.organizationVendorList.refreshList();
    },
    updateList() {
      this.$refs.organizationVendorList.updateList();
    },
  },
};
</script>
