<template>
  <div>
    <v-row class="mt-0 primary d-flex align-center">
      <v-col cols="10">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="text-white text-body-2 mr-1 font-weight-bold"
              v-bind="attrs"
              v-on="on"
            >
              {{ trace_id }}
            </span>
          </template>
          Trace ID
        </v-tooltip>
      </v-col>
      <v-col cols="1">
        <v-btn small depressed @click="$emit('closeDetails')" color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0 ma-0" cols="12">
        <v-card elevation="0" class="pa-0 ma-0">
          <v-divider></v-divider>
          <v-card-subtitle>
            <v-row no-gutters v-for="detail in details" :key="detail.label">
              <v-col cols="6">
                <h4 class="text-caption text--primary">
                  {{ detail.label }}
                </h4>
              </v-col>
              <v-col cols="6" class="d-flex justify-end">
                <h5>{{ detail.value ? detail.value : "-" }}</h5>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-title
            v-if="orderInformation && Object.keys(orderInformation).length"
          >
            <h4 class="text-body-2 font-weight-bold my-2">Payload</h4>
          </v-card-title>
          <v-card-subtitle>
            <v-row
              no-gutters
              v-for="(value, key, inx) in orderInformation"
              :key="inx"
            >
              <v-col>
                <h4 class="text-caption text-capitalize text--primary">
                  {{ key.replace(/_/g, " ") }}
                </h4>
              </v-col>
              <v-col
                class="d-flex justify-end"
                style="text-overflow: ellipsis; overflow: hidden"
              >
                <h5>{{ value }}</h5>

                <!-- <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <h5>{{ value }}</h5>
                    </span>
                  </template>
                  {{ value }}
                </v-tooltip> -->
              </v-col>
            </v-row>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "BaseLogDetailsLayout",
  props: {
    trace_id: { Number, String },
    details: Array,
    payloadDetails: Object,
  },
  data() {
    return {
      orderInformation: {},
    };
  },
  watch: {
    payloadDetails(val) {
      if (val) {
        this.orderInformation = this.payloadDetails;
      }
    },
  },
  mounted() {
    if (this.payloadDetails) {
      delete this.payloadDetails?.order_reference_number;
    }
    this.orderInformation = this.payloadDetails;
  },

  computed: {},
};
</script>
