import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialog',{attrs:{"title":`${_vm.editMode ? 'Edit' : 'Add'} User`,"width":"80vh"},on:{"closeDialog":function($event){_vm.resetForm(), (_vm.showUserForm = false)}},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c(VForm,{ref:"userForm",attrs:{"id":"userForm","name":"userForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pr-3 pb-3",attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","hide-details":"auto","label":"First Name*","rules":[(val) => !!val || 'First Name is required']},model:{value:(_vm.userDetails.first_name),callback:function ($$v) {_vm.$set(_vm.userDetails, "first_name", $$v)},expression:"userDetails.first_name"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Last Name*","rules":[(val) => !!val || 'Last Name is required']},model:{value:(_vm.userDetails.last_name),callback:function ($$v) {_vm.$set(_vm.userDetails, "last_name", $$v)},expression:"userDetails.last_name"}})],1),_c(VCol,{staticClass:"pr-3 pb-3",attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"outlined":"","hide-details":"auto","dense":"","label":"User Name*","rules":[(val) => !!val || 'User Name is required'],"error-messages":_vm.formErrors && _vm.formErrors.username ? _vm.formErrors.username : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.username
                ? delete _vm.formErrors.username
                : ''}},model:{value:(_vm.userDetails.username),callback:function ($$v) {_vm.$set(_vm.userDetails, "username", $$v)},expression:"userDetails.username"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Email ID","error-messages":_vm.formErrors && _vm.formErrors.email ? _vm.formErrors.email : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.email ? delete _vm.formErrors.email : ''}},model:{value:(_vm.userDetails.email),callback:function ($$v) {_vm.$set(_vm.userDetails, "email", $$v)},expression:"userDetails.email"}})],1),_c(VCol,{staticClass:"pr-3 pb-3",attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"type":"password","outlined":"","hide-details":"auto","dense":"","label":"Password*","rules":_vm.editMode
                ? []
                : [
                    (val) => !!val || 'Password is required',
                    (val) =>
                      (val && val.length >= 8) ||
                      'Password must be 8 character long',
                  ]},model:{value:(_vm.userDetails.password),callback:function ($$v) {_vm.$set(_vm.userDetails, "password", $$v)},expression:"userDetails.password"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"type":"password","outlined":"","hide-details":"auto","dense":"","label":"Confirm Password*","rules":_vm.editMode
                ? []
                : [
                    (val) => !!val || 'Confirm Password is required',
                    (val) =>
                      !_vm.userDetails.password ||
                      val === _vm.userDetails.password ||
                      'Password & Confirm Password must be same',
                  ]},model:{value:(_vm.userDetails.confirm_password),callback:function ($$v) {_vm.$set(_vm.userDetails, "confirm_password", $$v)},expression:"userDetails.confirm_password"}})],1),_c(VCol,{staticClass:"pr-3 pb-3",attrs:{"cols":"6"}},[_c('BaseSelect',{attrs:{"label":"Select Organization*","isClearable":true,"itemsList":_vm.organizationList,"item-text":"name","item-value":"id"},model:{value:(_vm.userDetails.organization),callback:function ($$v) {_vm.$set(_vm.userDetails, "organization", $$v)},expression:"userDetails.organization"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('BaseSelect',{attrs:{"label":"Select Client","itemsList":_vm.clientList,"isClearable":true,"item-text":"name","item-value":"id"},model:{value:(_vm.userDetails.client),callback:function ($$v) {_vm.$set(_vm.userDetails, "client", $$v)},expression:"userDetails.client"}})],1),_c(VCol,{staticClass:"pr-3",attrs:{"cols":"6"}},[_c('BaseSelect',{attrs:{"label":"Select User Type","itemsList":_vm.userTypeList,"isClearable":true,"item-text":"display_value","item-value":"value"},model:{value:(_vm.userDetails.user_type),callback:function ($$v) {_vm.$set(_vm.userDetails, "user_type", $$v)},expression:"userDetails.user_type"}})],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [(!_vm.editMode)?_c(VBtn,{attrs:{"color":"primary","small":""},on:{"click":_vm.resetForm}},[_vm._v(" Reset ")]):_vm._e(),_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.isValid,"small":""},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("Submit")])]},proxy:true}]),model:{value:(_vm.showUserForm),callback:function ($$v) {_vm.showUserForm=$$v},expression:"showUserForm"}})
}
var staticRenderFns = []

export { render, staticRenderFns }