<template>
  <BaseDialog
    v-model="showJsonEditorDialog"
    title="Update"
    max-width="600"
    @closeDialog="showJsonEditorDialog = false"
  >
    <template #dialogContent>
      <div v-if="resendErrors && Object.keys(resendErrors).length">
        <v-row v-for="(value, key, inx) in resendErrors" :key="inx">
          <v-col cols="12">{{ key }}:{{ value }}</v-col>
        </v-row>
      </div>
      <JsonEditorVue v-model="data" @json-save="save" showBtns :mode="'code'" />
    </template>
  </BaseDialog>
</template>
<script>
import JsonEditorVue from "vue-json-editor";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";

export default {
  name: "JsonEditorModal",
  components: { JsonEditorVue, BaseDialog },
  props: {
    value: Boolean,
    payload: Object,
    id: Number,
    resendErrors: { type: Object, default: null },
  },
  data() {
    return {
      data: {},
    };
  },
  watch: {
    showJsonEditorDialog(value) {
      if (value) {
        this.data = this.payload;
      }
    },
  },
  computed: {
    showJsonEditorDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    save(data) {
      this.$emit("save", data, this.id);
    },
  },
};
</script>
<style>
.jsoneditor-vue .jsoneditor-outer {
  min-height: 400px;
}
</style>
